import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Button, Table, Row, Col } from "antd";

import { getCourses } from "store/course/courseSlice";
import { getTopicById, updateTopic } from "store/topic/topicSlice";
import {
  getLessonsByTopicId,
  removeLesson,
  selectLesson,
} from "store/lesson/lessonSlice";
import Spinner from "components/Spinner/Spinner.component";
import Notification from "components/Notification/Notification.component";
import topicLessonColumns from "helpers/topicLessonColumns";
import TopicForm from "../../components/TopicForm/TopicForm";
import { StyledHeader, StyledLessonHeader } from "./TopicEdit.styles";

const LessonTable = ({ lessons, onDelete, id }) => {
  return (
    <div>
      <StyledLessonHeader>
        <h1>Lessons</h1>
        <Button type="primary">
          <Link to={`/topics/${id}/lessons/add`}>Add</Link>
        </Button>
      </StyledLessonHeader>
      <Table
        rowKey="id"
        dataSource={lessons}
        columns={topicLessonColumns(onDelete)}
      />
    </div>
  );
};

const TopicEdit = () => {
  const { isLoading, currentTopic } = useSelector((state) => state.topic);
  const { courses } = useSelector((state) => state.course);
  const { lessonsLoading, lessons } = useSelector((state) => state.lesson);
  const dispatch = useDispatch();

  const [editable, setEditable] = useState(false);
  const [error, setError] = useState({});
  const { id } = useParams();

  useEffect(() => {
    dispatch(getCourses());

    dispatch(getLessonsByTopicId(id));
    dispatch(getTopicById({ topicId: id }));
  }, [dispatch, id]);

  const onFinish = async (values) => {
    const res = await dispatch(updateTopic({ ...values, id }));
    if (res.error && res.error.message) {
      setError({ message: res.error.message });
      return;
    }
    setError({});
    setEditable(false);
    Notification("topicUpdated");
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onDelete = ({ record }) => {
    dispatch(selectLesson(record));
    // eslint-disable-next-line no-alert
    const message = window.confirm("Do you really want to remove this?");
    if (message === true) {
      dispatch(removeLesson(record.id)).then(() =>
        Notification("lessonRemoved")
      );
    }
  };

  return (
    <div className="site-layout-background">
      <Link to="/topics">Back</Link>
      <StyledHeader>
        <h1>{editable ? "Edit" : ""} Topic</h1>
        <span className="edit-button">
          <Button
            type="secondary"
            onClick={() => {
              setEditable(!editable);
            }}
          >
            {editable ? "View" : "Edit"}
          </Button>
        </span>
      </StyledHeader>
      {isLoading ? (
        <Spinner />
      ) : (
        <Row>
          <Col span={12}>
            <p style={{ color: "red" }}>{error && error.message}</p>
            <TopicForm
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              topicData={currentTopic}
              editable={!editable}
              courses={courses}
              formType="ADD"
            />
          </Col>
          <Col span={12}>
            {currentTopic.thumbnail && (
              <img width="300" src={currentTopic.thumbnail} alt="thumbnail" />
            )}
          </Col>
        </Row>
      )}

      {lessonsLoading ? (
        <Spinner />
      ) : (
        <LessonTable lessons={lessons} onDelete={onDelete} id={id} />
      )}
    </div>
  );
};

export default TopicEdit;
