import React from "react";
import Routes from "./Routes";
import "antd/dist/antd.css";

const App = () => <Routes />;

// import {
//   BrowserRouter as Router,
//   Switch,
//   Route,
//   Redirect,
// } from "react-router-dom";
// // import { useAuth, ProvideAuth } from "./services-mock/auth-context";
// // import Login from "./pages/Login/Login.component";
// // import CourseList from "./pages/CourseList/CourseList.component";
// // import TopicList from "./pages/TopicList/TopicList.component";
// // import LessonList from "./pages/LessonList/LessonList.component";
// // import CourseEdit from "./pages/CourseEdit/CourseEdit.component";
// // import CourseAdd from "./pages/CourseAdd/CourseAdd.component";
// // import LessonEdit from "./pages/LessonEdit/LessonEdit.component";
// // import LessonAdd from "./pages/LessonAdd/LessonAdd.component";
// // import TopicEdit from "./pages/TopicEdit/TopicEdit.component";
// // import TopicAdd from "./pages/TopicAdd/TopicAdd.component";
// // import Dashboard from "./components/Dashboard/Dashboard.component";

// // function PrivateRoute({ children, ...rest }) {
// //   const auth = useAuth();
// //   return (
// //     <Route
// //       // eslint-disable-next-line react/jsx-props-no-spreading
// //       {...rest}
// //       render={({ location }) =>
// //         auth.user ? (
// //           children
// //         ) : (
// //           <Redirect
// //             to={{
// //               pathname: "/login",
// //               state: { from: location },
// //             }}
// //           />
// //         )
// //       }
// //     />
// //   );
// // }

// // function ProtectedPage() {
// //   return (
// //     <Router>
// //       <Dashboard>
// //         <Switch>
// //           <Route exact path="/topics/add">
// //             <TopicAdd />
// //           </Route>
// //           <Route exact path="/topics/:id">
// //             <TopicEdit />
// //           </Route>
// //           <Route exact path="/lessons/add">
// //             <LessonAdd />
// //           </Route>
// //           <Route exact path="/lessons/:id">
// //             <LessonEdit />
// //           </Route>
// //           <Route exact path="/courses/add">
// //             <CourseAdd />
// //           </Route>
// //           <Route exact path="/courses/:id">
// //             <CourseEdit />
// //           </Route>
// //           <Route path="/topics">
// //             <TopicList />
// //           </Route>
// //           <Route path="/lessons">
// //             <LessonList />
// //           </Route>
// //           <Route path="/courses">
// //             <CourseList />
// //           </Route>
// //           <Route path="/">
// //             <CourseList />
// //           </Route>
// //         </Switch>
// //       </Dashboard>
// //     </Router>
// //   );
// // }

// // const App = () => {
// //   return (
// //     <ProvideAuth>
// //       <Router>
// //         <Switch>
// //           <Route path="/login">
// //             <Login />
// //           </Route>
// //           <PrivateRoute>
// //             <ProtectedPage />
// //           </PrivateRoute>
// //         </Switch>
// //       </Router>
// //     </ProvideAuth>
// //   );
// // };

export default App;
