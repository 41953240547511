import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Table } from "antd";
import { getCourses } from "store/course/courseSlice";
import {
  filterTopics,
  // getTopics,
  removeTopic,
  selectTopic,
} from "store/topic/topicSlice";
import Notification from "components/Notification/Notification.component";
import initialTopicFilter from "constants/initialTopicFilter";
import topicColumns from "helpers/topicColumns";
import DropdownFilter from "components/DropdownFilter/DropdownFilter";
import SearchFilter from "components/SearchFilter/SearchFilter";
import Spinner from "../../components/Spinner/Spinner.component";
import { StyledHeader } from "./TopicList.styles";

const TopicList = () => {
  const dispatch = useDispatch();
  const { topicsLoading, topics } = useSelector((state) => state.topic);
  const { courses } = useSelector((state) => state.course);
  const [filter, setFilter] = useState(initialTopicFilter);

  useEffect(() => {
    dispatch(filterTopics(initialTopicFilter));
    dispatch(getCourses());
  }, [dispatch]);

  const onSearch = (value) => {
    const data = { ...filter, search: value };
    setFilter(data);
    dispatch(filterTopics(data));
  };

  const onFilter = (value) => {
    const data = { ...filter, courseId: value };
    setFilter(data);
    dispatch(filterTopics(data));
  };

  const onDelete = ({ record }) => {
    dispatch(selectTopic(record));
    // eslint-disable-next-line no-alert
    const message = window.confirm("Do you really want to remove this?");
    if (message === true) {
      dispatch(removeTopic(record.id)).then(() => Notification("topicRemoved"));
    }
  };

  return (
    <div className="site-layout-background">
      <h1>Topics</h1>
      <StyledHeader>
        <SearchFilter
          placeholder="input search text"
          onSearch={onSearch}
          defaultValue={filter.search}
        />
        <div>
          <DropdownFilter
            data={courses}
            onFilter={onFilter}
            defaultValue={filter.courseId}
            placeholder="Filter by course"
          />
          <Button type="primary">
            <Link to="/topics/add">Add</Link>
          </Button>
        </div>
      </StyledHeader>
      {topicsLoading ? (
        <Spinner />
      ) : (
        <Table
          rowKey="id"
          dataSource={topics}
          columns={topicColumns(onDelete)}
        />
      )}
    </div>
  );
};

export default TopicList;
