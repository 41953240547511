import { BrainGameData, IVMModule } from "./activityDropdown";

export const activityTypeOption = {
  1: "Video",
  2: "IVM",
  3: "Single Question",
  4: "Multiple Question",
  5: "Game",
  6: "Information",
};

export const singleMultiple = [
  { questionId: 3, activityType: 3, name: "Single Question" },
  { questionId: 4, activityType: 4, name: "Multiple Questions" },
];

export const otherTypes = [
  { questionId: 1, activityType: 1, name: "Video" },
  { questionId: 2, activityType: 2, name: "IVM" },
  { questionId: 5, activityType: 5, name: "Game" },
  { questionId: 6, activityType: 6, name: "Information" },
];

export const activityNameOption = [
  {
    activityId: 1,
    activityName: "Let's Talk About",
    activityType: 6,
    label: "Payload <Text>",
    placeholder: "Please input the text",
    instruction: "",
    options: [],
    rules: [
      {
        required: true,
        message: "Please do not leave it blank!",
      },
    ],
  },
  {
    activityId: 2,
    activityName: "Fun With Math",
    activityType: 1,
    label: "Payload <Video URL>",
    placeholder: "Please input the video URL",
    instruction:
      "Insert URL in HTTPS format -> https://www.youtube.com/watch?v=GuQ8Tg1O-GA",
    options: [],
    rules: [
      {
        required: true,
        message: "Please do not leave it blank!",
      },
    ],
  },
  {
    activityId: 3,
    activityName: "Watch and Learn",
    activityType: 1,
    label: "Payload <Video URL>",
    placeholder: "Please input the video URL",
    instruction:
      "Insert URL in HTTPS format -> https://www.youtube.com/watch?v=GuQ8Tg1O-GA",
    options: [],
    rules: [
      {
        required: true,
        message: "Please do not leave it blank!",
      },
    ],
  },
  {
    activityId: 4,
    activityName: "Do and Learn",
    activityType: 4,
    label: "Payload <Array of Ids>",
    placeholder: "Please input the array.",
    instruction: "Insert payload in array format ->  eg: [100,101,102]",
    options: [],
    rules: [
      {
        required: true,
        message: "Please do not leave it blank!",
      },
      {
        pattern: /^\[\d+(?:,\s*\d+)*\]$/g,
        message: "Please supply the correct array.",
      },
    ],
  },
  {
    activityId: 5,
    activityName: "Hands-On Activity (IVM)",
    activityType: 2,
    label: "Payload <Select IVM Module>",
    placeholder: "Please select IVM Module",
    instruction: "Once selected the module, we'll take care of the rest!",
    options: IVMModule,
    rules: [
      {
        required: true,
        message: "Please do not leave it blank!",
      },
    ],
  },
  {
    activityId: 6,
    activityName: "Hands-On Activity (Brain Game)",
    activityType: 5,
    label: "Payload <Select Brain Game Module>",
    placeholder: "Please select Brain Game Module",
    instruction: "Once selected the module, we'll take care of the rest!",
    options: BrainGameData,
    rules: [
      {
        required: true,
        message: "Please do not leave it blank!",
      },
    ],
  },
  {
    activityId: 7,
    activityName: "Self-Check",
    activityType: 6,
    label: "Payload <Text>",
    placeholder: "Please input the [Tick]: Description",
    instruction:
      "Insert payload in this format -> [Tick] Know what an angle is. \n[Tick] Can identify a right angle, an acute angle or an obtuse angle.",
    options: [],
    rules: [
      {
        required: true,
        message: "Please do not leave it blank!",
      },
    ],
  },
  {
    activityId: 8,
    activityName: "Fun With Science",
    activityType: 1,
    label: "Payload <Video URL>",
    placeholder: "Please input the video URL",
    instruction:
      "Insert URL in HTTPS format -> https://www.youtube.com/watch?v=GuQ8Tg1O-GA",
    options: [],
    rules: [
      {
        required: true,
        message: "Please do not leave it blank!",
      },
    ],
  },
];
