import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { resetCourse, addCourse } from "store/course/courseSlice";

import CourseForm from "components/CourseForm/CourseForm";
import Notification from "components/Notification/Notification.component";
import ErrorMessage from "helpers/ErrorMessage";
import initialCourse from "constants/initialCourse";

const CourseAdd = () => {
  const [error, setError] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    setError({});
    dispatch(resetCourse());
  }, [dispatch]);

  const onFinish = async (values) => {
    const res = await dispatch(addCourse(values));
    if (res.error && res.error.message) {
      setError({ message: res.error.message });
      return;
    }
    setError({});
    history.push("/courses");
    Notification("courseAdded");
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="site-layout-background">
      <Link to="/courses">Back</Link>
      <h1>Add Course</h1>
      <ErrorMessage>{error && error.message}</ErrorMessage>
      <CourseForm
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        courseData={initialCourse}
        editable={false}
        formType="ADD"
      />
    </div>
  );
};

export default CourseAdd;
