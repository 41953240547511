import React from "react";
import { Button, Space } from "antd";
import styled from "styled-components";
import { activityTypeOption } from "constants/activityTypes";

const StyledButton = styled(Button)`
  padding: 0px;
  margin: 0px;
`;

const activityColumns = ({ showDrawer, form, onDelete }) => {
  return [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Display Order",
      dataIndex: "displayOrder",
      key: "displayOrder",
    },
    {
      title: "Type",
      key: "activityType",
      // eslint-disable-next-line react/display-name
      render: ({ activityType }) => (
        <>
          <span>{activityTypeOption[activityType]}</span>
        </>
      ),
    },
    {
      title: "Max Points",
      dataIndex: "maxPoints",
      key: "maxPoints",
    },
    {
      title: "Action",
      key: "action",
      // eslint-disable-next-line react/display-name
      render: (_text, record) => {
        const {
          id,
          activityType,
          name,
          payload,
          displayOrder,
          scrolls,
          maxPoints,
          description,
        } = record;

        return (
          <Space size="middle">
            <StyledButton
              type="link"
              onClick={() => {
                form.setFieldsValue({
                  id,
                  activityType,
                  name,
                  payload,
                  displayOrder,
                  scrolls,
                  maxPoints,
                  description,
                });
                showDrawer();
              }}
            >
              View
            </StyledButton>
            |
            <StyledButton type="link" onClick={() => onDelete({ record })}>
              Delete
            </StyledButton>
          </Space>
        );
      },
    },
  ];
};

export default activityColumns;
