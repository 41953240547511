import React, { useEffect, useState } from "react";
import { Form, Input, InputNumber, Button, Select, Alert } from "antd";
import {
  activityNameOption,
  singleMultiple,
  otherTypes,
} from "constants/activityTypes";
// import { IVMModule, BrainGameData } from "constants/activityDropdown";
// import { BrainGameData } from "constants/activityDropdown";

const { TextArea } = Input;
const { Option } = Select;

const initialActivityOption = {
  activityId: 0,
  activityName: "",
  activityType: null,
  label: "Activity <Payload>",
  placeholder: null,
  instruction: null,
  defaultValue: null,
  options: [],
};

const ActivityForm = ({
  form,
  id,
  onFinishActivity,
  onFinishActivityFailed,
  visible,
}) => {
  const IVMtext = activityNameOption[4].activityName;
  const brainGameText = activityNameOption[5].activityName;
  const actName = form.getFieldsValue(true).name;
  const actType = form.getFieldsValue(true).activityType;

  const [options, setOptions] = useState({
    array: [],
    disabled: true,
  });
  const [activityOption, setActivityOption] = useState(initialActivityOption);

  const onChangeActivityName = () => {
    setOptions({
      array: otherTypes.concat(singleMultiple),
      disabled: true,
    });
  };

  const parsePayload = (activityName, payload) => {
    try {
      // TODOS: Parse for corrupted data
      let res = payload;
      if (typeof payload === "string") {
        res = JSON.parse(payload);
      }
      switch (activityName) {
        case activityNameOption[0].activityName: // Let's Talk About
          return res.Description;
        case activityNameOption[1].activityName: // Fun With Math
          return res.Url;
        case activityNameOption[2].activityName: // Watch and Learn
          return res.Url;
        case activityNameOption[3].activityName: // Do and Learn
          return JSON.stringify(res.Questions);
        case activityNameOption[4].activityName: // Hands On Activity IVM
          return res.Id;
        case activityNameOption[5].activityName: // Hands On Activity Brain Game
          return res.Id;
        case activityNameOption[6].activityName: // Self-Check
          return res.Description;
        case activityNameOption[7].activityName: // Fun With Science
          return res.Url;
        default:
          return res.Description;
      }
    } catch {
      return JSON.stringify(payload);
    }
  };

  useEffect(() => {
    const activityName = form.getFieldsValue(true).name;
    const result = activityNameOption.filter(
      (option) => option.activityName === activityName
    )[0];
    if (result !== undefined) {
      setActivityOption(result);
    } else {
      setActivityOption(initialActivityOption);
    }
    onChangeActivityName(activityName);

    const { payload } = form.getFieldsValue(true);
    const payloadText = parsePayload(activityName, payload);
    form.setFieldsValue({
      payloadText,
    });
  }, [form, visible]);

  // console.log("form", form.getFieldsValue(true));

  const onChangeActivity = (value) => {
    // console.log("values", value);
    const result = activityNameOption.filter(
      (option) => option.activityName === value
    )[0];
    const { activityType } = result;
    setActivityOption(result);
    form.setFieldsValue({ activityType, payloadText: null });
    onChangeActivityName(value);
  };

  const onChangeActivityType = (value) => {
    // console.log("value", value);
    form.setFieldsValue({ value });
  };

  return (
    <Form
      form={form}
      initialValues={{
        lessonId: id,
      }}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      onFinish={onFinishActivity}
      onFinishFailed={onFinishActivityFailed}
    >
      <Form.Item label="id" name="id" hidden>
        <Input />
      </Form.Item>
      <Form.Item label="lessonId" name="lessonId" hidden>
        <Input />
      </Form.Item>
      <Form.Item label="Activity Name" name="name">
        <Select placeholder="Activity Name" onChange={onChangeActivity}>
          {activityNameOption.map(({ activityId, activityName }) => {
            return (
              <Option key={activityId} value={activityName}>
                {activityName}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
      {actName && (
        <Form.Item label="Activity Type" name="activityType">
          <Select
            placeholder="Activity Type"
            onChange={onChangeActivityType}
            disabled
          >
            {options.array.map(({ questionId, activityType, name }) => {
              return (
                <Option
                  disabled={options.disabled}
                  key={questionId}
                  value={activityType}
                >
                  {name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
      )}
      {/* For Brain Game & IVM, select options */}

      {/* {JSON.stringify(form.getFieldsValue(true).payload)} */}
      {/* {JSON.stringify(activityOption)} */}
      {actName && [brainGameText, IVMtext].includes(actName) && (
        <Form.Item label={activityOption?.label} name="payloadText">
          <Select
            placeholder={activityOption?.placeholder}
            onChange={onChangeActivityType}
            rules={[
              {
                required: true,
                message: "Please select activity!",
              },
            ]}
          >
            {activityOption.options.map(({ Id, Name }) => {
              return (
                <Option key={Id} value={Id}>
                  {Name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
      )}
      {actName && ![IVMtext, brainGameText].includes(actName) && (
        <span>
          <Form.Item
            label={activityOption?.label}
            name="payloadText"
            rules={activityOption.rules}
          >
            <Input placeholder={activityOption.placeholder} />
          </Form.Item>
        </span>
      )}
      {activityOption.instruction && (
        <>
          <Alert
            message="Instruction"
            description={activityOption.instruction}
            type="info"
          />
          <br />
        </>
      )}
      <Form.Item label="JSON Payload" name="payload">
        <Input disabled />
      </Form.Item>
      <Form.Item
        label="Display Order"
        name="displayOrder"
        rules={[
          {
            required: true,
            message: "Please input display order!",
          },
        ]}
      >
        <InputNumber />
      </Form.Item>
      <Form.Item
        label="Max Points"
        name="maxPoints"
        rules={[
          {
            required: true,
            type: "number",
            min: 0,
            message: "Max Points must be more than/equal to 0",
          },
        ]}
      >
        <InputNumber disabled={![3, 4].includes(actType)} />
      </Form.Item>
      <Form.Item
        label="Description"
        name="description"
        rules={[
          {
            required: true,
            message: "Please input description!",
          },
        ]}
      >
        <TextArea />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ActivityForm;
