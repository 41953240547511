import { notification } from "antd";

export const condition = {
  login: {
    type: "success",
    message: "Success",
    description: "You're logged in successfully!",
  },
  loginError: {
    type: "error",
    message: "Incorrect login",
    description: "Your username/password is incorrect.",
  },
  logout: {
    type: "success",
    message: "Success",
    description: "You're logged out successfully!",
  },
  courseAdded: {
    type: "success",
    message: "Success",
    description: "Course has been added successfully.",
  },
  courseUpdated: {
    type: "success",
    message: "Success",
    description: "Course has been updated successfully.",
  },
  courseRemoved: {
    type: "success",
    message: "Success",
    description: "Course has been removed successfully.",
  },
  topicAdded: {
    type: "success",
    message: "Success",
    description: "Topic has been added successfully.",
  },
  topicUpdated: {
    type: "success",
    message: "Success",
    description: "Topic has been updated successfully.",
  },
  topicRemoved: {
    type: "success",
    message: "Success",
    description: "Topic has been removed successfully.",
  },
  lessonAdded: {
    type: "success",
    message: "Success",
    description: "Lesson has been added successfully.",
  },
  lessonUpdated: {
    type: "success",
    message: "Success",
    description: "Lesson has been updated successfully.",
  },
  lessonRemoved: {
    type: "success",
    message: "Success",
    description: "Lesson has been removed successfully.",
  },
  activityAdded: {
    type: "success",
    message: "Success",
    description: "Activity has been added successfully.",
  },
  activityUpdated: {
    type: "success",
    message: "Success",
    description: "Activity has been updated successfully.",
  },
  activityRemoved: {
    type: "success",
    message: "Success",
    description: "Activity has been removed successfully.",
  },
  invalidRoles: {
    type: "error",
    message: "Invalid Role",
    description:
      "This account is not admin/content admin, please use another account.",
  },
};

const Notification = (currentCondition) => {
  const result = condition[currentCondition];

  notification[result.type]({
    message: result.message,
    description: result.description,
    duration: 3,
  });
};

export default Notification;
