import React from "react";
import { Form, Input, InputNumber, Button } from "antd";

const CourseForm = ({ onFinish, onFinishFailed, courseData, editable }) => {
  const { name, displayOrder } = courseData;

  return (
    <Form
      name="basic"
      initialValues={{
        name,
        displayOrder,
      }}
      style={{ paddingTop: "50px" }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        label="Name"
        name="name"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 6 }}
        rules={[
          {
            required: true,
            message: "Please input name!",
          },
        ]}
      >
        <Input disabled={editable} />
      </Form.Item>
      <Form.Item
        label="Display Order"
        name="displayOrder"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 6 }}
      >
        <InputNumber disabled={editable} />
      </Form.Item>
      {!editable && (
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      )}
    </Form>
  );
};

export default CourseForm;
