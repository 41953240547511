import handleResponse from "helpers/handleAPIResponse";
import store from "store/index";

const baseUrl = process.env.REACT_APP_API_DOMAIN;

// get courses
export const fetchCourses = () => {
  return fetch(`${baseUrl}config/course/GetAll`, {
    method: "GET",
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.isSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Get all courses failed"); // eslint-disable-line
    });
};

// get course by Id
export const fetchCourseById = ({ courseId }) => {
  return fetch(`${baseUrl}config/course/GetById/${courseId}`, {
    method: "GET",
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.isSuccessful) {
        throw new Error(result.message);
      } else {
        return result.result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Get course by id failed"); // eslint-disable-line
    });
};

// get filtered courses
export const fetchFilteredCourses = (keywords) => {
  return fetch(
    `${baseUrl}config/course/GetFilteredList?searchStr=${keywords}`,
    {
      method: "GET",
      headers: {
        authorization: `Bearer ${localStorage.getItem("access_token")}`,
        Timezone: store.getState().login.timezone,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.isSuccessful) {
        throw new Error(result.message.message);
      } else {
        return result.result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Get filtered course failed"); // eslint-disable-line
    });
};

export const createCourseData = (params) => {
  return fetch(`${baseUrl}config/course/Add`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
      Timezone: store.getState().login.timezone,
    },
    body: JSON.stringify(params),
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.isSuccessful) {
        throw new Error(result.message.message);
      }
      return result.result;
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Create course failed"); // eslint-disable-line
    });
};

export const updateCourseData = (params) => {
  const { id, name, displayOrder } = params;
  return fetch(`${baseUrl}config/course/Update/${id}`, {
    method: "PUT",
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
      Timezone: store.getState().login.timezone,
    },
    body: JSON.stringify({ name, displayOrder, thumbnail: "string" }),
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.isSuccessful) {
        throw new Error(result.message.message);
      } else {
        return result.result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Update course failed"); // eslint-disable-line
    });
};

export const deleteCourseData = (id) => {
  return fetch(`${baseUrl}config/course/Delete/${id}`, {
    method: "DELETE",
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.isSuccessful) {
        throw new Error(result.message.message);
      } else {
        return result.result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Delete course failed"); // eslint-disable-line
    });
};
