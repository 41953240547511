import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Layout, Menu, Button, message } from "antd";

// eslint-disable-next-line import/no-cycle
import logout from "helpers/logout";
import Notification from "components/Notification/Notification.component";
// eslint-disable-next-line import/no-cycle
import resetSeed from "../../services/seedData";
// import { useAuth } from "../../services-mock/auth-context";

const { Sider } = Layout;

const Sidebar = () => {
  // Component States
  const [selected, setSelected] = useState(["1"]);

  // Event Handlers
  const signOut = () => {
    logout();
    Notification("logout");
  };

  const resetDBSeed = async () => {
    message.loading({ content: "Loading...", key: "resetDB" });
    try {
      await resetSeed();
      message.success({
        content: "DB Seed reset!",
        key: "resetDB",
        duration: 2,
      });
    } catch (err) {
      message.error({
        content: err?.message ?? "Reset DB seed failed!",
        key: "resetDB",
        duration: 2,
      });
      console.log(err);
    }
  };

  return (
    <Sider
      style={{ paddingTop: "100px" }}
      breakpoint="lg"
      collapsedWidth="0"
      onBreakpoint={(broken) => {
        console.log(broken);
      }}
    >
      <div className="logo" />
      <Menu
        theme="dark"
        selectedKeys={selected}
        onClick={({ key }) => {
          setSelected([key]);
        }}
      >
        <Menu.Item key="1">
          Courses
          <Link to="/courses" />
        </Menu.Item>
        <Menu.Item key="2">
          Topics
          <Link to="/topics" />
        </Menu.Item>
        <Menu.Item key="3">
          Lessons
          <Link to="/lessons" />
        </Menu.Item>
      </Menu>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: 100,
        }}
      >
        <Button
          className="sign-out"
          ghost
          onClick={resetDBSeed}
          style={{
            marginBottom: "1rem",
          }}
        >
          Reset DB Seed
        </Button>
        <Button className="sign-out" ghost onClick={signOut}>
          Sign out
        </Button>
      </div>
    </Sider>
  );
};

export default Sidebar;
