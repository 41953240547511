import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Button, Table } from "antd";
import {
  filterLessons,
  selectLesson,
  removeLesson,
} from "store/lesson/lessonSlice";
import Notification from "components/Notification/Notification.component";
import Spinner from "components/Spinner/Spinner.component";
import { getTopics } from "store/topic/topicSlice";
import lessonColumns from "helpers/lessonColumns";
import initialLessonFilter from "constants/initialLessonFilter";
import SearchFilter from "components/SearchFilter/SearchFilter";
import DropdownFilter from "components/DropdownFilter/DropdownFilter";
import { StyledHeader } from "./LessonList.styles";

const LessonList = () => {
  const dispatch = useDispatch();
  const { lessonsLoading, lessons } = useSelector((state) => state.lesson);
  const { topics } = useSelector((state) => state.topic);
  const [filter, setFilter] = useState(initialLessonFilter);

  useEffect(() => {
    dispatch(filterLessons(initialLessonFilter));
    dispatch(getTopics());
  }, [dispatch]);

  const onSearch = (value) => {
    const data = { ...filter, search: value };
    setFilter(data);
    dispatch(filterLessons(data));
  };

  const onFilter = (value) => {
    const data = { ...filter, topicId: value };
    setFilter(data);
    dispatch(filterLessons(data));
  };

  const onDelete = ({ record }) => {
    dispatch(selectLesson(record));
    // eslint-disable-next-line no-alert
    const message = window.confirm("Do you really want to remove this?");
    if (message === true) {
      dispatch(removeLesson(record.id)).then(() =>
        Notification("lessonRemoved")
      );
    }
  };

  return (
    <div className="site-layout-background">
      <h1>Lessons</h1>
      <StyledHeader>
        <SearchFilter
          placeholder="input search text"
          onSearch={onSearch}
          defaultValue={filter.search}
        />
        <div>
          <>
            <DropdownFilter
              data={topics}
              onFilter={onFilter}
              defaultValue={filter.topicId}
              placeholder="Filter by topic"
            />
            <Button type="primary">
              <Link to="/lessons/add">Add</Link>
            </Button>
          </>
        </div>
      </StyledHeader>
      {lessonsLoading ? (
        <Spinner />
      ) : (
        <Table
          rowKey="id"
          dataSource={lessons}
          columns={lessonColumns(onDelete)}
        />
      )}
    </div>
  );
};

export default LessonList;
