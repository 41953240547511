import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { newLoginRequest, newRefreshTokenRequest } from "services/login";
import { history } from "Routes";
import logout from "helpers/logout";
import { isNil } from "ramda";
// eslint-disable-next-line camelcase
import jwt_decode from "jwt-decode";
import Notification from "components/Notification/Notification.component";

// HELPERS
const checkIsParentAccount = (roles) => {
  return !isNil(roles.find((role) => role.Id === 8));
};

const checkAdminOrContentRole = (roles) => {
  return roles.some(
    (role) => role.Name === "Admin" || role.Name === "ContentUpload"
  );
};

const initialState = {
  user: null,
  isParentAccount: null,
  userID: null,
  isLoading: false,
  error: null,
  timezone: "Asia/Singapore",
  mySecret: "",
  language: "",
};

export const getToken = createAsyncThunk("login/getToken", async (params) => {
  const { username, password } = params;
  try {
    const response = await newLoginRequest(username, password);
    const decoded = jwt_decode(response.access_token);
    const { role } = decoded;
    const roles = JSON.parse(role);
    const checkRoles = checkAdminOrContentRole(roles);
    if (checkRoles) {
      localStorage.setItem("access_token", response.access_token);
      localStorage.setItem("refresh_token", response.refresh_token);
      history.push("/");
      Notification("login");
    } else {
      Notification("invalidRoles");
    }
    return response;
  } catch (err) {
    throw new Error(err?.message ?? "Login failed");
  }
});

export const refreshToken = createAsyncThunk("login/refreshToken", async () => {
  try {
    const response = await newRefreshTokenRequest();
    if (!isNil(response.access_token)) {
      localStorage.setItem("access_token", response.access_token);
    }
    if (!isNil(response.refresh_token)) {
      localStorage.setItem("refresh_token", response.refresh_token);
    }
    return response;
  } catch (err) {
    logout();
    throw new Error(err?.message ?? "Refresh token failed");
  }
});

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {},
  extraReducers: {
    [getToken.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getToken.fulfilled]: (state, action) => {
      const decoded = jwt_decode(action.payload.access_token);
      const { role, myLocale } = decoded;
      const roles = JSON.parse(role);

      state.user = decoded;
      state.isParentAccount = checkIsParentAccount(roles);
      state.userID = parseInt(decoded.user_id, 10);
      state.isLoading = false;
      state.timezone = decoded.myTimeZone;
      state.mySecret = decoded.mySecret;
      if (!isNil(myLocale)) {
        state.language = JSON.parse(myLocale);
      }
    },
    [getToken.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
    [refreshToken.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [refreshToken.fulfilled]: (state, action) => {
      const decoded = jwt_decode(action.payload.access_token);
      const { role, myLocale } = decoded;
      const roles = JSON.parse(role);
      state.user = decoded;
      state.isParentAccount = checkIsParentAccount(roles);
      state.userID = parseInt(decoded.user_id, 10);
      state.isLoading = false;
      state.timezone = decoded.myTimeZone;
      state.mySecret = decoded.mySecret;
      if (!isNil(myLocale)) {
        state.language = JSON.parse(myLocale);
      }
    },
    [refreshToken.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
  },
});

const { reducer } = loginSlice;
export default reducer;
