import React from "react";
import { Form, Input, InputNumber, Button, Select } from "antd";

// const fileProps = {
//   name: "file",
//   action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
//   headers: {
//     authorization: "authorization-text",
//   },
//   onChange(info) {
//     if (info.file.status !== "uploading") {
//       console.log(info.file, info.fileList);
//     }
//     if (info.file.status === "done") {
//       message.success(`${info.file.name} file uploaded successfully`);
//     } else if (info.file.status === "error") {
//       message.error(`${info.file.name} file upload failed.`);
//     }
//   },
// };

const TopicForm = ({
  onFinish,
  onFinishFailed,
  topicData,
  editable,
  courses,
}) => {
  // const { fileName, action, headers, onChange } = fileProps;
  const { name, displayOrder, thumbnail, courseId, maxXp } = topicData;

  return (
    <Form
      name="basic"
      initialValues={{
        name,
        displayOrder,
        thumbnail,
        courseId,
        maxXp,
      }}
      style={{ paddingTop: "20px" }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        label="Name"
        name="name"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 12 }}
        rules={[
          {
            required: true,
            message: "Please input name!",
          },
        ]}
      >
        <Input disabled={editable} />
      </Form.Item>
      <Form.Item
        label="Display Order"
        name="displayOrder"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 12 }}
      >
        <InputNumber disabled={editable} />
      </Form.Item>
      <Form.Item
        label="Max Points"
        name="maxXp"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 12 }}
        rules={[
          {
            required: true,
            type: "number",
            min: 0,
            message: "Max Points must be more than/equal to 0",
          },
        ]}
      >
        <InputNumber disabled={editable} />
      </Form.Item>
      <Form.Item
        label="Course Name"
        name="courseId"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 12 }}
      >
        <Select disabled={editable}>
          {courses.map((course) => (
            <Select.Option key={course.id} value={course.id}>
              {course.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="maxXp" noStyle>
        <Input type="hidden" />
      </Form.Item>
      <Form.Item
        label="Thumbnail"
        name="thumbnail"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 12 }}
      >
        <Input disabled={editable} />
        {/* <Upload
          name={fileName}
          action={action}
          headers={headers}
          onChange={onChange}
        >
          <Button>Click to Upload</Button>
        </Upload> */}
      </Form.Item>
      {!editable && (
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      )}
    </Form>
  );
};

export default TopicForm;
