import styled from "styled-components";

export const LoginContainer = styled.div`
  padding-top: 200px;
`;

export const LoginTitle = styled.h1`
  display: flex;
  justify-content: center;
`;
