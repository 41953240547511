import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCourses,
  removeCourse,
  filterCourses,
  selectCourse,
} from "store/course/courseSlice";
import { Link } from "react-router-dom";
import { Button, Table } from "antd";
import Notification from "components/Notification/Notification.component";
import courseColumns from "helpers/courseColumns";
import Spinner from "../../components/Spinner/Spinner.component";
import { StyledHeader, StyledSearch } from "./CourseList.styles";

const CourseList = () => {
  const dispatch = useDispatch();
  const { coursesLoading, courses } = useSelector((state) => state.course);

  useEffect(() => {
    dispatch(getCourses());
  }, [dispatch]);

  const onSearch = (value) => {
    if (value === "") {
      dispatch(getCourses());
    } else {
      dispatch(filterCourses(value));
    }
  };

  const onDelete = ({ record }) => {
    dispatch(selectCourse(record));
    // eslint-disable-next-line no-alert
    const message = window.confirm("Do you really want to remove this?");
    if (message === true) {
      dispatch(removeCourse(record.id)).then(() =>
        Notification("courseRemoved")
      );
    }
  };

  return (
    <div className="site-layout-background">
      <h1>Courses</h1>
      <StyledHeader>
        <StyledSearch placeholder="input search text" onSearch={onSearch} />
        <Button type="primary">
          <Link to="/courses/add">Add</Link>
        </Button>
      </StyledHeader>
      {coursesLoading ? (
        <Spinner />
      ) : (
        <Table
          rowKey="id"
          dataSource={courses}
          columns={courseColumns(onDelete)}
        />
      )}
    </div>
  );
};

export default CourseList;
