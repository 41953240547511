import handleResponse from "helpers/handleAPIResponse";
// eslint-disable-next-line import/no-cycle
import store from "store/index";

const baseUrl = process.env.REACT_APP_API_DOMAIN;

// get lessons
const resetSeed = () => {
  return fetch(`${baseUrl}config/DataSeed/SeedData`, {
    method: "GET",
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.isSuccessful) {
        throw new Error(result.message.message);
      } else {
        return result.result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Reset seed data failed"); // eslint-disable-line
    });
};

export default resetSeed;
