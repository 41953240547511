import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { getCourses } from "store/course/courseSlice";
import { resetTopic, addTopic } from "store/topic/topicSlice";
import TopicForm from "components/TopicForm/TopicForm";
import Notification from "components/Notification/Notification.component";
import ErrorMessage from "helpers/ErrorMessage";
import initialTopic from "constants/initialTopic";

const TopicAdd = () => {
  const { courses } = useSelector((state) => state.course);
  const [error, setError] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    setError({});
    dispatch(getCourses());
    dispatch(resetTopic());
  }, [dispatch]);

  const onFinish = async (values) => {
    const res = await dispatch(addTopic(values));
    if (res.error && res.error.message) {
      setError({ message: res.error.message });
      return;
    }
    setError({});
    history.push("/topics");
    Notification("topicAdded");
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="site-layout-background">
      <Link to="/topics">Back</Link>
      <h1>Add Topic</h1>
      <ErrorMessage>{error && error.message}</ErrorMessage>
      <TopicForm
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        topicData={initialTopic}
        editable={false}
        courses={courses}
        formType="ADD"
      />
    </div>
  );
};

export default TopicAdd;
