import styled from "styled-components";
import { Layout } from "antd";

const { Footer, Content } = Layout;

export const DashboardLayout = styled(Layout)`
  min-height: 100vh;
`;

export const DashboardContent = styled(Content)`
  margin: 24px 16px 0;
  padding: 24px;
`;

export const DashboardFooter = styled(Footer)`
  text-align: center;
`;
