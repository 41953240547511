import React, { useEffect } from "react";
import { Router, Switch, Route } from "react-router-dom";
// eslint-disable-next-line import/no-extraneous-dependencies
import { createBrowserHistory } from "history";
import Login from "pages/Login/Login.component";
// import { refreshToken } from "store/Login/loginSlice";
import AuthenticatedRoute from "components/Shared/AuthenticatedRoute";
// import { featureToggle } from "constants/index";
// import ExpireContainer from "pages/Expired/index";
import LoginRedirect from "pages/LoginRedirect";
import CourseEdit from "pages/CourseEdit/CourseEdit.component";
import CourseList from "pages/CourseList/CourseList.component";
import CourseAdd from "pages/CourseAdd/CourseAdd.component";
import TopicAdd from "pages/TopicAdd/TopicAdd.component";
import TopicEdit from "pages/TopicEdit/TopicEdit.component";
import TopicLessonAdd from "pages/TopicLessonAdd/TopicLessonAdd.component";
import TopicLessonEdit from "pages/TopicLessonEdit/TopicLessonEdit.component";
import TopicList from "pages/TopicList/TopicList.component";
import LessonAdd from "pages/LessonAdd/LessonAdd.component";
import LessonEdit from "pages/LessonEdit/LessonEdit.component";
import LessonList from "pages/LessonList/LessonList.component";

export const history = createBrowserHistory({
  basename: process.env.REACT_APP_SUBDOMAIN,
});

const ExternalLogin = () => {
  const loginUrl = process.env.REACT_APP_ADMIN_LOGIN_URL;
  useEffect(() => {
    window.location.href = loginUrl;
  }, [loginUrl]);
  return null;
};

const Routes = () => {
  const env =
    process.env.NODE_ENV === "development" ||
    process.env.REACT_APP_NETLIFY === "true"
      ? "dev"
      : "prod";
  return (
    <Router history={history}>
      <Switch>
        <Route path="/redirect" component={LoginRedirect} />
        <AuthenticatedRoute exact path="/courses/add" component={CourseAdd} />
        <AuthenticatedRoute exact path="/courses/:id" component={CourseEdit} />
        <AuthenticatedRoute exact path="/courses" component={CourseList} />
        <AuthenticatedRoute
          exact
          path="/topics/:topicId/lessons/add"
          component={TopicLessonAdd}
        />
        <AuthenticatedRoute
          exact
          path="/topics/:topicId/lessons/:id"
          component={TopicLessonEdit}
        />

        <AuthenticatedRoute exact path="/topics/add" component={TopicAdd} />
        <AuthenticatedRoute exact path="/topics/:id" component={TopicEdit} />
        <AuthenticatedRoute exact path="/topics" component={TopicList} />
        <AuthenticatedRoute exact path="/lessons/add" component={LessonAdd} />
        <AuthenticatedRoute exact path="/lessons/:id" component={LessonEdit} />
        <AuthenticatedRoute exact path="/lessons" component={LessonList} />
        <AuthenticatedRoute exact path="/" component={CourseList} />
        <Route
          exact
          path="/login"
          component={env === "dev" ? Login : ExternalLogin}
        />
        {/* {featureToggle.kooClass && (
          <AuthenticatedRoute path="/kooClass" component={KooClass} />
        )} */}
        {/* <AuthenticatedRoute component={Dashboard} /> */}
      </Switch>
    </Router>
  );
};

export default Routes;
