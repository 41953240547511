// const initialLessong = {
//   id: null,
//   topicId: "3d5241c2-b04f-4cb4-9450-72133a522188",
//   name: "",
//   thumbnail: null,
//   displayOrder: null,
//   description: null,
//   minAge: 6,
//   maxAge: 7,
//   minGrade: 1,
//   maxpoints: 6,
//   minPointsToUnlock: 20,
//   lessonStatus: 0,
//   currentUserPoint: null,
//   status: 1,
// };

const initialLesson = {
  id: null,
  name: "",
  displayOrder: null,
  maxpoints: 0,
  minPointsToUnlock: 20,
};

export default initialLesson;
