import React from "react";
import styled from "styled-components";

const StyledErrorMessage = styled.p`
  color: red;
`;

const ErrorMessage = ({ children }) => (
  <StyledErrorMessage>{children}</StyledErrorMessage>
);

export default ErrorMessage;
