import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchCourses,
  fetchCourseById,
  createCourseData,
  updateCourseData,
  deleteCourseData,
} from "services/courses";
import { fetchWrapper } from "services/login";
import initialCourse from "constants/initialCourse";

export const getCourses = createAsyncThunk("courses/getAll", async () => {
  try {
    const res = await fetchWrapper(fetchCourses);
    return res;
  } catch (error) {
    throw new Error(error?.message ?? "Get all courses failed");
  }
});

export const getCourseById = createAsyncThunk(
  "courses/getCourseById",
  async (params) => {
    try {
      const res = await fetchWrapper(fetchCourseById, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? "Get course failed");
    }
  }
);

export const filterCourses = createAsyncThunk(
  "courses/filter",
  async (value) => {
    try {
      // const res = await fetchFilteredCourses(value);
      const courses = await fetchWrapper(fetchCourses);
      const data = courses.filter((course) =>
        course.name.toLowerCase().includes(value.toLowerCase())
      );
      return data;
    } catch (error) {
      throw new Error(error?.message ?? "Get all courses failed");
    }
  }
);

export const addCourse = createAsyncThunk(
  "courses/addCourse",
  async (params) => {
    try {
      const res = await fetchWrapper(createCourseData, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? "Add course failed");
    }
  }
);

export const updateCourse = createAsyncThunk(
  "courses/updateCourse",
  async (params) => {
    try {
      const res = await fetchWrapper(updateCourseData, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? "Update course failed");
    }
  }
);

export const removeCourse = createAsyncThunk(
  "courses/removeCourse",
  async (id) => {
    try {
      const res = await fetchWrapper(deleteCourseData, id);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? "Remove course failed");
    }
  }
);

const initialState = {
  courses: [],
  coursesLoading: false,
  coursesError: null,
  filteredCourses: [],
  isLoading: false,
  isError: null,
  currentCourse: initialCourse,
};

const courseSlice = createSlice({
  name: "courseSlice",
  initialState,
  reducers: {
    resetCourse: (state) => {
      state.currentCourse = initialCourse;
    },
    selectCourse: (state, { payload }) => {
      state.currentCourse = payload;
    },
  },
  extraReducers: {
    [getCourses.pending]: (state) => {
      state.coursesLoading = true;
      state.courses = [];
      state.coursesError = null;
    },
    [getCourses.fulfilled]: (state, { payload }) => {
      state.coursesLoading = false;
      state.courses = payload;
      state.coursesError = null;
    },
    [getCourses.rejected]: (state, action) => {
      state.coursesLoading = false;
      state.courses = [];
      state.coursesError = action.error.message;
    },
    [filterCourses.pending]: (state) => {
      state.coursesLoading = true;
    },
    [filterCourses.fulfilled]: (state, { payload }) => {
      state.coursesLoading = false;
      state.courses = payload;
    },
    [filterCourses.rejected]: (state) => {
      state.coursesLoading = false;
    },
    [getCourseById.pending]: (state) => {
      state.isLoading = true;
      state.isError = null;
    },
    [getCourseById.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.currentCourse = action.payload;
      state.isError = null;
    },
    [getCourseById.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = action.error.message;
    },
    [updateCourse.pending]: (state) => {
      state.isError = null;
    },
    [updateCourse.fulfilled]: (state, action) => {
      state.currentCourse = action.payload;
      state.isError = null;
    },
    [updateCourse.rejected]: (state, action) => {
      state.isError = action.error.message;
    },
    [removeCourse.pending]: (state) => {
      state.isError = null;
    },
    [removeCourse.fulfilled]: (state, { payload }) => {
      if (payload === true) {
        const index = state.courses.findIndex(
          (course) => course.id === state.currentCourse.id
        );
        if (index !== -1) {
          state.courses.splice(index, 1);
        }
      }
      state.isError = null;
    },
    [removeCourse.rejected]: (state, action) => {
      state.isError = action.error.message;
    },
  },
});

export const { resetCourse, selectCourse } = courseSlice.actions;

const { reducer } = courseSlice;
export default reducer;
