import React from "react";
import { Input } from "antd";
import styled from "styled-components";

const { Search } = Input;

const StyledSearch = styled(Search)`
  width: 200px;
  padding-bottom: 10px;
`;

const SearchFilter = ({ onSearch, defaultValue, placeholder }) => {
  return (
    <StyledSearch
      className="search-input"
      placeholder={placeholder}
      onSearch={onSearch}
      defaultValue={defaultValue}
    />
  );
};

export default SearchFilter;
