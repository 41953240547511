// const initialTopicFilter = {
//   search: "hooo",
//   courseId: "713e4172-a15a-4174-838a-81cf3ab7b29e",
// };

const initialTopicFilter = {
  search: "",
  courseId: "ALL",
};

export default initialTopicFilter;
