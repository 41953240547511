import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchActivities,
  fetchActivityById,
  fetchFilteredActivities,
  createActivityData,
  updateActivityData,
  deleteActivityData,
} from "services/activities";
import { fetchWrapper } from "services/login";

export const getActivities = createAsyncThunk(
  "activities/getAll",
  async (id) => {
    try {
      const res = await fetchWrapper(fetchActivities);
      const data = res.filter((activity) => activity.lessonID === id);
      return data;
    } catch (error) {
      throw new Error(error?.message ?? "Get all activities failed");
    }
  }
);

export const getActivityById = createAsyncThunk(
  "activities/getActivityById",
  async (params) => {
    try {
      const res = await fetchWrapper(fetchActivityById, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? "Get activity failed");
    }
  }
);

export const filterActivities = createAsyncThunk(
  "activities/filter",
  async (value) => {
    try {
      const res = await fetchWrapper(fetchFilteredActivities, value);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? "Get all activities failed");
    }
  }
);

export const addActivity = createAsyncThunk(
  "activities/addActivity",
  async (params) => {
    try {
      const res = await fetchWrapper(createActivityData, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? "Add activity failed");
    }
  }
);

export const updateActivity = createAsyncThunk(
  "activities/updateActivity",
  async (params) => {
    try {
      const res = await fetchWrapper(updateActivityData, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? "Update activity failed");
    }
  }
);

export const removeActivity = createAsyncThunk(
  "activities/removeActivity",
  async (id) => {
    try {
      const res = await fetchWrapper(deleteActivityData, id);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? "Remove activity failed");
    }
  }
);

const initialActivity = {
  id: null,
  name: "",
  displayOrder: null,
};

const initialState = {
  activities: [],
  activitiesLoading: false,
  activitiesError: null,
  topicDropdown: [],
  filteredActivities: [],
  isLoading: false,
  isError: null,
  currentActivity: initialActivity,
};

const activitySlice = createSlice({
  name: "activitySlice",
  initialState,
  reducers: {
    resetActivity: (state) => {
      state.currentActivity = initialActivity;
    },
    selectActivity: (state, action) => {
      state.currentActivity = action.payload;
    },
  },
  extraReducers: {
    [getActivities.pending]: (state) => {
      state.activitiesLoading = true;
      state.activities = [];
      state.activitiesError = null;
    },
    [getActivities.fulfilled]: (state, { payload }) => {
      state.activitiesLoading = false;
      state.activities = payload;
      state.activitiesError = null;
    },
    [getActivities.rejected]: (state, action) => {
      state.activitiesLoading = false;
      state.activities = [];
      state.activitiesError = action.error.message;
    },
    [filterActivities.pending]: (state) => {
      state.activitiesLoading = true;
    },
    [filterActivities.fulfilled]: (state, { payload }) => {
      state.activitiesLoading = false;
      state.activities = payload.list;
    },
    [filterActivities.rejected]: (state) => {
      state.activitiesLoading = false;
    },
    [getActivityById.pending]: (state) => {
      state.isLoading = true;
      state.isError = null;
    },
    [getActivityById.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.currentActivity = action.payload;
      state.isError = null;
    },
    [getActivityById.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = action.error.message;
    },
    [addActivity.pending]: (state) => {
      state.isLoading = true;
      state.isError = null;
    },
    [addActivity.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.currentActivity = action.payload;
      state.activities.push(action.payload);
      state.isError = null;
    },
    [addActivity.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = action.error.message;
    },
    [updateActivity.pending]: (state) => {
      state.isError = null;
    },
    [updateActivity.fulfilled]: (state, action) => {
      const { activities } = state;
      state.currentActivity = action.payload;
      state.activities = activities.map((activity) =>
        activity.id === action.payload.id ? action.payload : activity
      );
      state.isError = null;
    },
    [updateActivity.rejected]: (state, action) => {
      state.isError = action.error.message;
    },
    [removeActivity.pending]: (state) => {
      state.activitiesLoading = true;
      state.isError = null;
    },
    [removeActivity.fulfilled]: (state, { payload }) => {
      if (payload === true) {
        const index = state.activities.findIndex(
          (activity) => activity.id === state.currentActivity.id
        );
        if (index !== -1) {
          state.activities.splice(index, 1);
        }
      }
      state.activitiesLoading = false;
      state.isError = null;
    },
    [removeActivity.rejected]: (state, action) => {
      state.activitiesLoading = false;
      state.isError = action.error.message;
    },
  },
});

export const { resetActivity, selectActivity } = activitySlice.actions;

const { reducer } = activitySlice;
export default reducer;
