import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import initialLesson from "constants/initialLesson";
import {
  fetchLessons,
  fetchLessonById,
  createLessonData,
  updateLessonData,
  deleteLessonData,
} from "services/lessons";
import { fetchWrapper } from "services/login";

export const getLessonsByTopicId = createAsyncThunk(
  "activities/getLessonByTopicId",
  async (id) => {
    try {
      const res = await fetchWrapper(fetchLessons);
      const data = res.filter((lesson) => lesson.topicId === id);
      return data;
    } catch (error) {
      throw new Error(error?.message ?? "Get all activities failed");
    }
  }
);

export const getLessons = createAsyncThunk("lessons/getAll", async () => {
  try {
    const res = await fetchWrapper(fetchLessons);
    return res;
  } catch (error) {
    throw new Error(error?.message ?? "Get all lessons failed");
  }
});

export const getLessonById = createAsyncThunk(
  "lessons/getLessonById",
  async (params) => {
    try {
      const res = await fetchWrapper(fetchLessonById, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? "Get lesson failed");
    }
  }
);

export const filterLessons = createAsyncThunk(
  "lessons/filter",
  async (value) => {
    try {
      // const res = await fetchFilteredLessons(value);
      let lessons = await fetchWrapper(fetchLessons);
      if (value.search !== "") {
        lessons = lessons.filter((lesson) =>
          lesson.name.toLowerCase().includes(value.search.toLowerCase())
        );
      }
      if (value.topicId !== "ALL") {
        lessons = lessons.filter((lesson) =>
          lesson.topicId.includes(value.topicId)
        );
      }
      return lessons;
    } catch (error) {
      throw new Error(error?.message ?? "Get all lessons failed");
    }
  }
);

export const addLesson = createAsyncThunk(
  "lessons/addLesson",
  async (params) => {
    try {
      const res = await fetchWrapper(createLessonData, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? "Add lesson failed");
    }
  }
);

export const updateLesson = createAsyncThunk(
  "lessons/updateLesson",
  async (params) => {
    try {
      const res = await fetchWrapper(updateLessonData, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? "Update lesson failed");
    }
  }
);

export const removeLesson = createAsyncThunk(
  "lessons/removeLesson",
  async (id) => {
    try {
      const res = await fetchWrapper(deleteLessonData, id);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? "Remove lesson failed");
    }
  }
);

const initialState = {
  lessons: [],
  lessonsLoading: false,
  lessonsError: null,
  topicDropdown: [],
  filteredLessons: [],
  isLoading: false,
  isError: null,
  currentLesson: initialLesson,
};

const lessonSlice = createSlice({
  name: "lessonSlice",
  initialState,
  reducers: {
    resetLesson: (state) => {
      state.currentLesson = initialLesson;
    },
    resetLessonWithTopicId: (state, { payload }) => {
      state.currentLesson = { ...initialLesson, topicId: payload };
    },
    selectLesson: (state, { payload }) => {
      state.currentLesson = payload;
    },
  },
  extraReducers: {
    [getLessonsByTopicId.pending]: (state) => {
      state.lessonsLoading = true;
      state.lessons = [];
      state.lessonsError = null;
    },
    [getLessonsByTopicId.fulfilled]: (state, { payload }) => {
      state.lessonsLoading = false;
      state.lessons = payload;
      state.lessonsError = null;
    },
    [getLessonsByTopicId.rejected]: (state, action) => {
      state.lessonsLoading = false;
      state.lessons = [];
      state.lessonsError = action.error.message;
    },
    [getLessons.pending]: (state) => {
      state.lessonsLoading = true;
      state.lessons = [];
      state.lessonsError = null;
    },
    [getLessons.fulfilled]: (state, { payload }) => {
      state.lessonsLoading = false;
      state.lessons = payload;
      state.lessonsError = null;
    },
    [getLessons.rejected]: (state, action) => {
      state.lessonsLoading = false;
      state.lessons = [];
      state.lessonsError = action.error.message;
    },
    [filterLessons.pending]: (state) => {
      state.lessonsLoading = true;
    },
    [filterLessons.fulfilled]: (state, { payload }) => {
      state.lessonsLoading = false;
      state.lessons = payload;
    },
    [filterLessons.rejected]: (state) => {
      state.lessonsLoading = false;
    },
    [getLessonById.pending]: (state) => {
      state.isLoading = true;
      state.isError = null;
    },
    [getLessonById.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.currentLesson = action.payload;
      state.isError = null;
    },
    [getLessonById.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = action.error.message;
    },
    [updateLesson.pending]: (state) => {
      state.isError = null;
    },
    [updateLesson.fulfilled]: (state, action) => {
      state.currentLesson = action.payload;
      state.isError = null;
    },
    [updateLesson.rejected]: (state, action) => {
      state.isError = action.error.message;
    },
    [removeLesson.pending]: (state) => {
      state.isError = null;
    },
    [removeLesson.fulfilled]: (state, { payload }) => {
      if (payload === true) {
        const index = state.lessons.findIndex(
          (lesson) => lesson.id === state.currentLesson.id
        );
        if (index !== -1) {
          state.lessons.splice(index, 1);
        }
      }
      state.isError = null;
    },
    [removeLesson.rejected]: (state, action) => {
      state.isError = action.error.message;
    },
  },
});

export const {
  resetLesson,
  selectLesson,
  resetLessonWithTopicId,
} = lessonSlice.actions;

const { reducer } = lessonSlice;
export default reducer;
