import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Button, Table, Drawer, Form, Row, Col } from "antd";
import { getTopics } from "store/topic/topicSlice";
import { getLessonById, updateLesson } from "store/lesson/lessonSlice";
import {
  getActivities,
  selectActivity,
  removeActivity,
  updateActivity,
  addActivity,
} from "store/activity/activitySlice";
import Spinner from "components/Spinner/Spinner.component";
import Notification from "components/Notification/Notification.component";
import LessonForm from "components/LessonForm/LessonForm";

import activityColumns from "helpers/activityColumns";
import ActivityForm from "components/ActivityForm/ActivityForm";
import ErrorMessage from "helpers/ErrorMessage";
import { BrainGameData, IVMModule } from "constants/activityDropdown";
import { activityNameOption } from "constants/activityTypes";
import { StyledHeader, StyledActivityHeader } from "./LessonEdit.styles";

const ActivityTable = ({ form, showDrawer, activityData, dispatch }) => {
  const onDelete = ({ record }) => {
    dispatch(selectActivity(record));
    // eslint-disable-next-line no-alert
    const message = window.confirm("Do you really want to remove this?");
    if (message === true) {
      dispatch(removeActivity(record.id));
      Notification("activityRemoved");
    }
  };

  return (
    <>
      <StyledActivityHeader>
        <h1>Activities</h1>
        <Button
          type="primary"
          onClick={() => {
            form.resetFields();
            showDrawer();
          }}
        >
          Add
        </Button>
      </StyledActivityHeader>
      <Table
        rowKey="id"
        dataSource={activityData}
        columns={activityColumns({ showDrawer, form, onDelete })}
      />
    </>
  );
};

const LessonEdit = () => {
  const [form] = Form.useForm();
  const { isLoading, currentLesson } = useSelector((state) => state.lesson);
  const { topics } = useSelector((state) => state.topic);
  const { activitiesLoading, activities } = useSelector(
    (state) => state.activity
  );
  const dispatch = useDispatch();

  const [visible, setVisible] = useState(false);
  const [editable, setEditable] = useState(false);
  const [error, setError] = useState({});
  const [activityError, setActivityError] = useState({});
  const { id } = useParams();

  useEffect(() => {
    dispatch(getTopics());
    dispatch(getActivities(id));
    dispatch(getLessonById({ lessonId: id }));
  }, [dispatch, id]);

  const showDrawer = () => {
    setVisible(true);
    setActivityError({});
  };

  const onClose = () => {
    setVisible(false);
  };

  const onFinish = async (values) => {
    const res = await dispatch(updateLesson({ ...values, id }));
    if (res.error && res.error.message) {
      setError({ message: res.error.message });
      return;
    }
    setError({});
    setEditable(false);
    Notification("lessonUpdated");
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const reconstructActivity = (values) => {
    // console.log(getActivityId);
    switch (values.name) {
      case activityNameOption[0].activityName:
        // Let's Talk About
        return {
          ...values,
          payload: JSON.stringify({
            Title: activityNameOption[0].activityName,
            Description: values.payloadText,
          }),
        };
      case activityNameOption[1].activityName:
        // Fun With Math
        return {
          ...values,
          payload: JSON.stringify({
            Title: activityNameOption[1].activityName,
            Description: values.description,
            Thumbnail: null,
            Url: values.payloadText,
          }),
        };
      case activityNameOption[2].activityName:
        // Watch and Learn
        return {
          ...values,
          payload: JSON.stringify({
            Title: activityNameOption[2].activityName,
            Description: values.description,
            Thumbnail: null,
            Url: values.payloadText,
          }),
        };
      case activityNameOption[3].activityName:
        // Do and Learn
        console.log("values", values);
        return {
          ...values,
          payload: JSON.stringify({
            Title: activityNameOption[3].activityName,
            Description: values.description,
            Questions: JSON.parse(values.payloadText),
          }),
        };
      case activityNameOption[4].activityName:
        // Hands On Activity IVM
        // eslint-disable-next-line no-case-declarations
        const ivmOption = IVMModule.find((x) => x.Id === values.payloadText);
        // console.log(ivmOption);
        return {
          ...values,
          name: activityNameOption[4].activityName,
          payload: JSON.stringify({
            Title: "Hands-On Activity",
            Description: values.description,
            Id: ivmOption.Id,
          }),
        };
      case activityNameOption[5].activityName:
        // Hands On Activity Brain Game
        // eslint-disable-next-line no-case-declarations
        const bgOption = BrainGameData.find((x) => x.Id === values.payloadText);
        // console.log(bgOption);
        return {
          ...values,
          name: activityNameOption[5].activityName,
          payload: JSON.stringify({
            Title: "Hands-On Activity",
            Description: values.description,
            Id: bgOption.Id,
            Thumbnail: bgOption.ThumbnailUrl,
            Path: bgOption.GameFolder,
            GameUrl: null,
          }),
        };
      case activityNameOption[6].activityName:
        // Self-Check
        return {
          ...values,
          payload: JSON.stringify({
            Title: activityNameOption[6].activityName,
            Description: values.payloadText,
          }),
        };
      case activityNameOption[7].activityName:
        // Fun With Science
        return {
          ...values,
          payload: JSON.stringify({
            Title: activityNameOption[7].activityName,
            Description: values.description,
            Thumbnail: null,
            Url: values.payloadText,
          }),
        };
      default:
        return values;
    }
  };

  const onFinishActivity = (values) => {
    console.log("Success:", values);
    const newData = reconstructActivity(values);
    console.log("result", JSON.stringify(newData));
    delete newData.payloadText;
    // TODOS: Validation for activity if the name is the same
    if (newData.id !== undefined) {
      dispatch(updateActivity(newData)).then((res) => {
        if (res.error && res.error.message) {
          setActivityError({ message: res.error.message });
          return;
        }
        Notification("activityUpdated");
        onClose();
      });
    } else {
      // eslint-disable-next-line no-param-reassign
      delete newData.id;
      dispatch(addActivity(newData)).then((res) => {
        if (res.error && res.error.message) {
          setActivityError({ message: res.error.message });
          return;
        }
        Notification("activityAdded");
        onClose();
      });
    }
  };

  const onFinishActivityFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="site-layout-background">
      <Link to="/lessons">Back</Link>
      <StyledHeader>
        <h1>{editable ? "Edit" : ""} Lesson</h1>
        <span className="edit-button">
          <Button
            type="secondary"
            onClick={() => {
              setEditable(!editable);
            }}
          >
            {editable ? "View" : "Edit"}
          </Button>
        </span>
      </StyledHeader>
      <div>
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <Row>
              <Col span={12}>
                <p style={{ color: "red" }}>{error && error.message}</p>
                <LessonForm
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  lessonData={currentLesson}
                  editable={!editable}
                  topics={topics}
                  formType="EDIT"
                />
              </Col>
              <Col span={11} offset={1}>
                {currentLesson.thumbnail && (
                  <img
                    width="300"
                    src={currentLesson.thumbnail}
                    alt="thumbnail"
                  />
                )}
              </Col>
            </Row>
          </>
        )}
        {activitiesLoading ? (
          <Spinner />
        ) : (
          <ActivityTable
            form={form}
            showDrawer={showDrawer}
            activityData={activities}
            dispatch={dispatch}
          />
        )}
        <Drawer
          title="Add Activity"
          placement="right"
          closable={false}
          onClose={onClose}
          visible={visible}
          width={400}
        >
          <ErrorMessage>{activityError && activityError.message}</ErrorMessage>
          <ActivityForm
            form={form}
            id={id}
            onFinishActivity={onFinishActivity}
            onFinishActivityFailed={onFinishActivityFailed}
            visible={visible}
          />
        </Drawer>
      </div>
    </div>
  );
};

export default LessonEdit;
