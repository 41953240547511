import React from "react";
import { Select } from "antd";
import styled from "styled-components";

const { Option } = Select;

const StyledSearch = styled(Select)`
  width: 200px;
  margin-right: 5px;
`;

const DropdownFilter = ({ data, onFilter, defaultValue, placeholder }) => {
  return (
    <StyledSearch
      placeholder={placeholder}
      onChange={onFilter}
      defaultValue={defaultValue}
    >
      <Option key="0" value="ALL">
        All
      </Option>
      {data.map(({ id, name }) => (
        <Option key={id} value={id}>
          {name}
        </Option>
      ))}
    </StyledSearch>
  );
};

export default DropdownFilter;
