import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getTopics } from "store/topic/topicSlice";
import { getLessonById, updateLesson } from "store/lesson/lessonSlice";
import { Button, Row, Col } from "antd";
import Spinner from "components/Spinner/Spinner.component";
import Notification from "components/Notification/Notification.component";

import LessonForm from "../../components/LessonForm/LessonForm";

// import activityData from "../../constants/activity-data";

const TopicLessonEdit = () => {
  const { isLoading, currentLesson } = useSelector((state) => state.lesson);
  const { topics } = useSelector((state) => state.topic);
  const dispatch = useDispatch();

  const [editable, setEditable] = useState(false);
  const [error, setError] = useState({});
  const { topicId, id } = useParams();

  useEffect(() => {
    dispatch(getTopics());
    dispatch(getLessonById({ lessonId: id }));
  }, [dispatch, id]);

  const onFinish = async (values) => {
    const res = await dispatch(updateLesson({ ...values, id }));
    if (res.error && res.error.message) {
      setError({ message: res.error.message });
      return;
    }
    setError({});
    setEditable(false);
    Notification("lessonUpdated");
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="site-layout-background">
      <Link to={`/topics/${topicId}`}>Back</Link>
      <div
        style={{
          paddingTop: "30px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h1>{editable ? "Edit" : ""} Lesson</h1>
        <span className="edit-button">
          <Button
            type="secondary"
            onClick={() => {
              setEditable(!editable);
            }}
          >
            {editable ? "View" : "Edit"}
          </Button>
        </span>
      </div>
      <div>
        {isLoading ? (
          <Spinner />
        ) : (
          <Row>
            <Col span={12}>
              <p style={{ color: "red" }}>{error && error.message}</p>
              <LessonForm
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                lessonData={currentLesson}
                editable={!editable}
                topics={topics}
                formType="EDIT"
              />
            </Col>
            <Col span={12}>
              <div style={{ paddingLeft: 20 }}>
                {currentLesson.thumbnail && (
                  <img
                    width="300"
                    src={currentLesson.thumbnail}
                    alt="thumbnail"
                  />
                )}
              </div>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

export default TopicLessonEdit;
