import { isNil } from "ramda";
import logout from "helpers/logout";

export const newLoginRequest = (username, password) => {
  const urlencoded = new URLSearchParams();
  urlencoded.append("grant_type", "password");
  urlencoded.append("username", username);
  urlencoded.append("password", password);
  urlencoded.append("client_id", process.env.REACT_APP_CLIENT_ID);
  urlencoded.append("client_secret", process.env.REACT_APP_CLIENT_SECRET);
  urlencoded.append("scope", process.env.REACT_APP_CLIENT_SCOPE);
  return fetch(`${process.env.REACT_APP_TOKEN_URL}`, {
    method: "POST",
    headers: {
      "content-type": "application/x-www-form-urlencoded",
    },
    body: urlencoded,
  })
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      if (!isNil(result.error)) {
        throw new Error(result.error_description);
      } else {
        return result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Login failed"); // eslint-disable-line
    });
};

export const loginRequest = (username, password) => {
  const urlencoded = new URLSearchParams();
  urlencoded.append("grant_type", "password");
  urlencoded.append("username", username);
  urlencoded.append("password", password);
  urlencoded.append("client_id", process.env.REACT_APP_CLIENT_ID);
  urlencoded.append("client_secret", process.env.REACT_APP_CLIENT_SECRET);
  return fetch(`${process.env.REACT_APP_API_DOMAIN}token`, {
    method: "POST",
    headers: {
      "content-type": "application/x-www-form-urlencoded",
    },
    body: urlencoded,
  })
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      if (!isNil(result.error)) {
        throw new Error(result.error_description);
      } else {
        return result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Login failed"); // eslint-disable-line
    });
};

export const newRefreshTokenRequest = (clientID) => {
  const urlencoded = new URLSearchParams();
  urlencoded.append("grant_type", "refresh_token");
  urlencoded.append(
    "client_id",
    isNil(clientID) ? process.env.REACT_APP_CLIENT_ID : clientID
  );
  urlencoded.append("client_secret", process.env.REACT_APP_CLIENT_SECRET);
  urlencoded.append("refresh_token", localStorage.getItem("refresh_token"));
  urlencoded.append("scope", process.env.REACT_APP_CLIENT_SCOPE);
  return fetch(`${process.env.REACT_APP_TOKEN_URL}`, {
    method: "POST",
    headers: {
      "content-type": "application/x-www-form-urlencoded",
    },
    body: urlencoded,
  })
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      if (!isNil(result.error)) {
        throw new Error(result.error);
      } else {
        return result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Refresh token failed"); // eslint-disable-line
    });
};

export const refreshTokenRequest = () => {
  const urlencoded = new URLSearchParams();
  urlencoded.append("grant_type", "refresh_token");
  urlencoded.append("client_id", process.env.REACT_APP_CLIENT_ID);
  urlencoded.append("client_secret", process.env.REACT_APP_CLIENT_SECRET);
  urlencoded.append("refresh_token", localStorage.getItem("refresh_token"));
  return fetch(`${process.env.REACT_APP_API_DOMAIN}token`, {
    method: "POST",
    headers: {
      "content-type": "application/x-www-form-urlencoded",
    },
    body: urlencoded,
  })
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      if (!isNil(result.error)) {
        throw new Error(result.error);
      } else {
        return result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Refresh token failed"); // eslint-disable-line
    });
};

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

// eslint-disable-next-line consistent-return
export const fetchWrapper = async (request, params) => {
  try {
    const result = await request(params);
    return result;
  } catch (err) {
    if (err.message === "401 Error") {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        }).then(() => {
          return request(params);
        });
      }
      isRefreshing = true;
      try {
        const refreshResult = await newRefreshTokenRequest();
        localStorage.setItem("access_token", refreshResult.access_token);
        localStorage.setItem("refresh_token", refreshResult.refresh_token);
        processQueue(null, refreshResult.access_token);
        isRefreshing = false;
        return request(params);
      } catch (err2) {
        // If refresh token request fails
        processQueue(err2, null);
        isRefreshing = false;
        if (err2.message === "invalid_grant") {
          logout();
        }
        logout();
      }
    } else {
      throw err;
    }
  }
};
