import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchTopics,
  fetchTopicById,
  createTopicData,
  updateTopicData,
  deleteTopicData,
} from "services/topics";
import { fetchWrapper } from "services/login";
import initialTopic from "constants/initialTopic";

export const getTopics = createAsyncThunk("topics/getAll", async () => {
  try {
    const res = await fetchWrapper(fetchTopics);
    return res;
  } catch (error) {
    throw new Error(error?.message ?? "Get all topics failed");
  }
});

export const getTopicById = createAsyncThunk(
  "topics/getTopicById",
  async (params) => {
    try {
      const res = await fetchWrapper(fetchTopicById, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? "Get topic failed");
    }
  }
);

export const filterTopics = createAsyncThunk("topics/filter", async (value) => {
  try {
    // const res = await fetchFilteredTopics(value);
    let topics = await fetchWrapper(fetchTopics);
    if (value.search !== "") {
      topics = topics.filter((topic) =>
        topic.name.toLowerCase().includes(value.search.toLowerCase())
      );
    }
    if (value.courseId !== "ALL") {
      topics = topics.filter((topic) =>
        topic.courseId.includes(value.courseId)
      );
    }
    return topics;
  } catch (error) {
    throw new Error(error?.message ?? "Get all topics failed");
  }
});

export const addTopic = createAsyncThunk("topics/addTopic", async (params) => {
  try {
    const res = await fetchWrapper(createTopicData, params);
    return res;
  } catch (error) {
    throw new Error(error?.message ?? "Add topic failed");
  }
});

export const updateTopic = createAsyncThunk(
  "topics/updateTopic",
  async (params) => {
    try {
      const res = await fetchWrapper(updateTopicData, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? "Update topic failed");
    }
  }
);

export const removeTopic = createAsyncThunk(
  "topics/removeTopic",
  async (id) => {
    try {
      const res = await fetchWrapper(deleteTopicData, id);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? "Remove topic failed");
    }
  }
);

const initialState = {
  topics: [],
  topicsLoading: false,
  topicsError: null,
  courseDropdown: [],
  filteredTopics: [],
  isLoading: false,
  isError: null,
  currentTopic: initialTopic,
};

const topicSlice = createSlice({
  name: "topicSlice",
  initialState,
  reducers: {
    resetTopic: (state) => {
      state.currentTopic = initialTopic;
    },
    selectTopic: (state, { payload }) => {
      state.currentTopic = payload;
    },
  },
  extraReducers: {
    [getTopics.pending]: (state) => {
      state.topicsLoading = true;
      state.topics = [];
      state.topicsError = null;
    },
    [getTopics.fulfilled]: (state, { payload }) => {
      state.topicsLoading = false;
      state.topics = payload;
      // state.courseDropdown = payload
      //   .map((item) => item.courseId)
      //   .filter((value, index, self) => self.indexOf(value) === index);
      state.topicsError = null;
    },
    [getTopics.rejected]: (state, action) => {
      state.topicsLoading = false;
      state.topics = [];
      state.topicsError = action.error.message;
    },
    [filterTopics.pending]: (state) => {
      state.topicsLoading = true;
    },
    [filterTopics.fulfilled]: (state, { payload }) => {
      state.topicsLoading = false;
      state.topics = payload;
    },
    [filterTopics.rejected]: (state) => {
      state.topicsLoading = false;
    },
    [getTopicById.pending]: (state) => {
      state.isLoading = true;
      state.isError = null;
    },
    [getTopicById.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.currentTopic = action.payload;
      state.isError = null;
    },
    [getTopicById.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = action.error.message;
    },
    [updateTopic.pending]: (state) => {
      state.isError = null;
    },
    [updateTopic.fulfilled]: (state, action) => {
      state.currentTopic = action.payload;
      state.isError = null;
    },
    [updateTopic.rejected]: (state, action) => {
      state.isError = action.error.message;
    },
    [removeTopic.pending]: (state) => {
      state.isError = null;
    },
    [removeTopic.fulfilled]: (state, { payload }) => {
      if (payload === true) {
        const index = state.topics.findIndex(
          (topic) => topic.id === state.currentTopic.id
        );
        if (index !== -1) {
          state.topics.splice(index, 1);
        }
      }
      state.isError = null;
    },
    [removeTopic.rejected]: (state, action) => {
      state.isError = action.error.message;
    },
  },
});

export const { resetTopic, selectTopic } = topicSlice.actions;

const { reducer } = topicSlice;
export default reducer;
