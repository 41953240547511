import React from "react";
import { Link } from "react-router-dom";
import { Button, Space } from "antd";
import styled from "styled-components";

const StyledDeleteButton = styled(Button)`
  padding: 0px;
  margin: 0px;
`;

const courseColumns = (onDelete) => [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Display Order",
    dataIndex: "displayOrder",
    key: "displayOrder",
  },
  {
    title: "No. of Topics",
    dataIndex: "topicCount",
    key: "topicCount",
  },
  {
    title: "Action",
    key: "action",
    // eslint-disable-next-line react/display-name
    render: (_text, record) => {
      const { id } = record;
      return (
        <Space size="middle">
          <Link
            to={{
              pathname: `/courses/${id}`,
              state: { id },
            }}
          >
            View
          </Link>
          |
          <StyledDeleteButton
            className="delete-button"
            type="link"
            onClick={() => onDelete({ record })}
          >
            Delete
          </StyledDeleteButton>
        </Space>
      );
    },
  },
];

export default courseColumns;
