// const initialTopic = {
//   id: null,
//   courseId: "dc693d08-37ff-493b-98f4-3302d29ff023",
//   name: "",
//   thumbnail: null,
//   displayOrder: null,
//   maxXp: 0,
// };

const initialTopic = {
  id: null,
  courseId: null,
  name: "",
  thumbnail: null,
  displayOrder: null,
  maxXp: 0,
};

export default initialTopic;
