import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Row, Col } from "antd";
import { getTopics } from "store/topic/topicSlice";
import { resetLesson, addLesson } from "store/lesson/lessonSlice";
import Notification from "components/Notification/Notification.component";
import initialLesson from "constants/initialLesson";
import LessonForm from "components/LessonForm/LessonForm";
import ErrorMessage from "helpers/ErrorMessage";

const LessonAdd = () => {
  const { topics } = useSelector((state) => state.topic);
  const [error, setError] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    setError({});
    dispatch(getTopics());
    dispatch(resetLesson());
  }, [dispatch]);

  const onFinish = async (values) => {
    const res = await dispatch(addLesson(values));
    if (res.error && res.error.message) {
      setError({ message: res.error.message });
      return;
    }
    setError({});
    history.push("/lessons");
    Notification("lessonAdded");
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="site-layout-background">
      <Link to="/lessons">Back</Link>
      <h1>Add Lesson</h1>
      <ErrorMessage>{error && error.message}</ErrorMessage>
      <Row>
        <Col md={12}>
          <LessonForm
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            lessonData={initialLesson}
            editable={false}
            topics={topics}
            formType="ADD"
          />
        </Col>
      </Row>
    </div>
  );
};

export default LessonAdd;
