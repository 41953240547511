import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Button } from "antd";
import { getCourseById, updateCourse } from "store/course/courseSlice";
import Spinner from "components/Spinner/Spinner.component";
import CourseForm from "components/CourseForm/CourseForm";
import Notification from "components/Notification/Notification.component";
import ErrorMessage from "helpers/ErrorMessage";
import { StyledEditHeader } from "./CourseEdit.styles";

const CourseEdit = () => {
  const { isLoading, currentCourse } = useSelector((state) => state.course);
  const dispatch = useDispatch();

  const [editable, setEditable] = useState(false);
  const [error, setError] = useState({});
  const { id } = useParams();

  useEffect(() => {
    dispatch(getCourseById({ courseId: id }));
  }, [dispatch, id]);

  const onFinish = async (values) => {
    const res = await dispatch(updateCourse({ ...values, id }));
    if (res.error && res.error.message) {
      setError({ message: res.error.message });
      return;
    }
    setError({});
    setEditable(false);
    Notification("courseUpdated");
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="site-layout-background">
      <Link to="/courses">Back</Link>
      <StyledEditHeader>
        <h1>{editable ? "Edit" : ""} Course</h1>
        <span className="edit-button">
          <Button
            type="secondary"
            onClick={() => {
              setEditable(!editable);
            }}
          >
            {!editable ? "View" : "Edit"}
          </Button>
        </span>
      </StyledEditHeader>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <ErrorMessage>{error && error.message}</ErrorMessage>
          <CourseForm
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            courseData={currentCourse}
            editable={!editable}
            formType="EDIT"
          />
        </>
      )}
    </div>
  );
};

export default CourseEdit;
