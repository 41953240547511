import handleResponse from "helpers/handleAPIResponse";
import store from "store/index";

const baseUrl = process.env.REACT_APP_API_DOMAIN;

// get lessons
export const fetchLessons = () => {
  return fetch(`${baseUrl}config/lesson/GetAll`, {
    method: "GET",
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.isSuccessful) {
        throw new Error(result.message.message);
      } else {
        return result.result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Get all lessons failed"); // eslint-disable-line
    });
};

// get lesson by Id
export const fetchLessonById = ({ lessonId }) => {
  return fetch(`${baseUrl}config/lesson/GetById/${lessonId}`, {
    method: "GET",
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.isSuccessful) {
        throw new Error(result.message);
      } else {
        return result.result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Get lesson by id failed"); // eslint-disable-line
    });
};

// get filtered lessons
export const fetchFilteredLessons = (keywords) => {
  return fetch(
    `${baseUrl}config/lesson/GetFilteredList?searchStr=${keywords}`,
    {
      method: "GET",
      headers: {
        authorization: `Bearer ${localStorage.getItem("access_token")}`,
        Timezone: store.getState().login.timezone,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.isSuccessful) {
        throw new Error(result.message.message);
      } else {
        return result.result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Get filtered lesson failed"); // eslint-disable-line
    });
};

export const createLessonData = (params) => {
  const {
    name,
    description,
    minAge,
    maxAge,
    displayOrder,
    thumbnail,
    topicId,
    maxpoints,
    minPointsToUnlock,
  } = params;
  return fetch(`${baseUrl}config/lesson/Add`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
      Timezone: store.getState().login.timezone,
    },
    body: JSON.stringify({
      name,
      description,
      minAge,
      maxAge,
      displayOrder,
      thumbnail,
      topicId,
      maxpoints,
      minPointsToUnlock,
    }),
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.isSuccessful) {
        throw new Error(result.message.message);
      } else {
        return result.result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Create lesson failed"); // eslint-disable-line
    });
};

export const updateLessonData = (params) => {
  const {
    id,
    name,
    description,
    minAge,
    maxAge,
    displayOrder,
    thumbnail,
    topicId,
    maxpoints,
    minPointsToUnlock,
  } = params;
  return fetch(`${baseUrl}config/lesson/Update/${id}`, {
    method: "PUT",
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
      Timezone: store.getState().login.timezone,
    },
    body: JSON.stringify({
      name,
      description,
      minAge,
      maxAge,
      displayOrder,
      thumbnail,
      topicId,
      maxpoints,
      minPointsToUnlock,
    }),
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.isSuccessful) {
        throw new Error(result.message.message);
      } else {
        return result.result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Update lesson failed"); // eslint-disable-line
    });
};

export const deleteLessonData = (id) => {
  return fetch(`${baseUrl}config/lesson/Delete/${id}`, {
    method: "DELETE",
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.isSuccessful) {
        throw new Error(result.message.message);
      } else {
        return result.result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Delete lesson failed"); // eslint-disable-line
    });
};
