import React from "react";
import { Link } from "react-router-dom";
import { Button, Space } from "antd";
import styled from "styled-components";

const StyledDeleteButton = styled(Button)`
  padding: 0px;
  margin: 0px;
`;

const topicLessonColumns = (onDelete) => [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Course",
    dataIndex: "courseName",
    key: "courseName",
  },
  {
    title: "Topic",
    dataIndex: "topicName",
    key: "topicName",
  },
  {
    title: "Display Order",
    dataIndex: "displayOrder",
    key: "displayOrder",
  },
  {
    title: "No. of Activities",
    dataIndex: "activityCount",
    key: "activityCount",
  },
  {
    title: "Max Points",
    dataIndex: "maxpoints",
    key: "maxpoints",
  },
  {
    title: "Action",
    key: "action",
    // eslint-disable-next-line react/display-name
    render: (_text, record) => (
      <Space size="middle">
        <Link
          to={{
            pathname: `${record.topicId}/lessons/${record.id}`,
            state: { id: record.id },
          }}
        >
          View
        </Link>
        |
        <StyledDeleteButton type="link" onClick={() => onDelete({ record })}>
          Delete
        </StyledDeleteButton>
      </Space>
    ),
  },
];

export default topicLessonColumns;
