import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { getTopics } from "store/topic/topicSlice";
import { addLesson, resetLessonWithTopicId } from "store/lesson/lessonSlice";
import { Row, Col } from "antd";
import Spinner from "components/Spinner/Spinner.component";
import Notification from "components/Notification/Notification.component";

import LessonForm from "../../components/LessonForm/LessonForm";

const TopicLessonAdd = () => {
  const { currentLesson } = useSelector((state) => state.lesson);
  const { topics, topicsLoading } = useSelector((state) => state.topic);
  const dispatch = useDispatch();
  const history = useHistory();

  const [error, setError] = useState({});
  const { topicId } = useParams();

  useEffect(() => {
    dispatch(getTopics());
    dispatch(resetLessonWithTopicId(topicId));
  }, [dispatch, topicId]);

  const onFinish = async (values) => {
    const res = await dispatch(addLesson({ ...values }));
    if (res.error && res.error.message) {
      setError({ message: res.error.message });
      return;
    }
    setError({});
    history.goBack();
    Notification("lessonAdded");
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="site-layout-background">
      <Link to={`/topics/${topicId}`}>Back</Link>
      <div
        style={{
          paddingTop: "30px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h1>Add Lesson</h1>
      </div>
      <div>
        {topicsLoading ? (
          <Spinner />
        ) : (
          <Row>
            <Col span={12}>
              <p style={{ color: "red" }}>{error && error.message}</p>
              <LessonForm
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                lessonData={currentLesson}
                editable={false}
                topics={topics}
                formType="ADD"
              />
            </Col>
            <Col span={12}>
              {currentLesson.thumbnail && (
                <img
                  width="300"
                  src={currentLesson.thumbnail}
                  alt="thumbnail"
                />
              )}
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

export default TopicLessonAdd;
