import styled from "styled-components";

export const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
`;

export const StyledLessonHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  margin-bottom: 10px;
`;

export default StyledLessonHeader;
