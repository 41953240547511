import handleResponse from "helpers/handleAPIResponse";
import store from "store/index";

const baseUrl = process.env.REACT_APP_API_DOMAIN;

// get activities
export const fetchActivities = () => {
  return fetch(`${baseUrl}config/activity/GetAll`, {
    method: "GET",
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.isSuccessful) {
        throw new Error(result.message.message);
      } else {
        return result.result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Get all activities failed"); // eslint-disable-line
    });
};

// get activity by Id
export const fetchActivityById = ({ activityId }) => {
  return fetch(`${baseUrl}config/activity/GetById/${activityId}`, {
    method: "GET",
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.isSuccessful) {
        throw new Error(result.message);
      } else {
        return result.result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Get activity by id failed"); // eslint-disable-line
    });
};

// get filtered activities
export const fetchFilteredActivities = (keywords) => {
  return fetch(
    `${baseUrl}config/activity/GetFilteredList?searchStr=${keywords}`,
    {
      method: "GET",
      headers: {
        authorization: `Bearer ${localStorage.getItem("access_token")}`,
        Timezone: store.getState().login.timezone,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.isSuccessful) {
        throw new Error(result.message.message);
      } else {
        return result.result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Get filtered activity failed"); // eslint-disable-line
    });
};

export const createActivityData = (params) => {
  return fetch(`${baseUrl}config/activity/Add`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
      Timezone: store.getState().login.timezone,
    },
    body: JSON.stringify(params),
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.isSuccessful) {
        throw new Error(result.message.message);
      } else {
        return result.result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Create activity failed"); // eslint-disable-line
    });
};

export const updateActivityData = (params) => {
  const { id } = params;
  return fetch(`${baseUrl}config/activity/Update/${id}`, {
    method: "PUT",
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
      Timezone: store.getState().login.timezone,
    },
    body: JSON.stringify(params),
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.isSuccessful) {
        throw new Error(result.message.message);
      } else {
        return result.result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Update activity failed"); // eslint-disable-line
    });
};

export const deleteActivityData = (id) => {
  return fetch(`${baseUrl}config/activity/Delete/${id}`, {
    method: "DELETE",
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.isSuccessful) {
        throw new Error(result.message.message);
      } else {
        return result.result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Delete activity failed"); // eslint-disable-line
    });
};
