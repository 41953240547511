export const IVMModule = [
  {
    Id: "1",
    Name: "Types of Angles",
    Category: "Angles, Triangles",
  },
  {
    Id: "12",
    Name: "Types of Triangles",
    Category: "Angles, Triangles",
  },
  {
    Id: "13",
    Name: "Triangle - Area and Perimeter",
    Category: "Angles, Triangles",
  },
  {
    Id: "21",
    Name: "Area of Triangle - Reasoning",
    Category: "Angles, Triangles",
  },
  {
    Id: "2",
    Name: "Circle - Basics",
    Category: "Circles, Quadrilaterals",
  },
  {
    Id: "11",
    Name: "Quadrilaterals",
    Category: "Circles, Quadrilaterals",
  },
  {
    Id: "16",
    Name: "Rectangle - Area",
    Category: "Circles, Quadrilaterals",
  },
  {
    Id: "19",
    Name: "Area of Circle - Reasoning",
    Category: "Circles, Quadrilaterals",
  },
  {
    Id: "22",
    Name: "Area of Rhombus - Reasoning",
    Category: "Circles, Quadrilaterals",
  },
  {
    Id: "4",
    Name: "Fractions - Basic",
    Category: "Fractions, Percentage, Decimals, Ratio",
  },
  {
    Id: "5",
    Name: "Fractions - Equivalent Fraction",
    Category: "Fractions, Percentage, Decimals, Ratio",
  },
  {
    Id: "3",
    Name: "Fraction - Percentage - Decimal",
    Category: "Fractions, Percentage, Decimals, Ratio",
  },
  {
    Id: "6",
    Name: "Fractions - Pizza",
    Category: "Fractions, Percentage, Decimals, Ratio",
  },
  {
    Id: "8",
    Name: "Percentage - Discount",
    Category: "Fractions, Percentage, Decimals, Ratio",
  },
  {
    Id: "9",
    Name: "Percentage (Part-Whole)",
    Category: "Fractions, Percentage, Decimals, Ratio",
  },
  {
    Id: "15",
    Name: "Ratio",
    Category: "Fractions, Percentage, Decimals, Ratio",
  },
  {
    Id: "14",
    Name: "Place Value",
    Category: "Numbers, Place Value, Four Operations",
  },
  {
    Id: "23",
    Name: "Addition & Place Value",
    Category: "Numbers, Place Value, Four Operations",
  },
  {
    Id: "26",
    Name: "Subtraction - 2 Digit Numbers",
    Category: "Numbers, Place Value, Four Operations",
  },
  {
    Id: "27",
    Name: "Subtraction - 4 Digit Numbers",
    Category: "Numbers, Place Value, Four Operations",
  },
  {
    Id: "28",
    Name: "Division - 2 Digit Numbers",
    Category: "Numbers, Place Value, Four Operations",
  },
  {
    Id: "29",
    Name: "Division - 3 Digit Numbers",
    Category: "Numbers, Place Value, Four Operations",
  },
  {
    Id: "30",
    Name: "Division - 4 Digit Numbers",
    Category: "Numbers, Place Value, Four Operations",
  },
  {
    Id: "31",
    Name: "Multiplication by 1 Digit Numbers",
    Category: "Numbers, Place Value, Four Operations",
  },
  {
    Id: "32",
    Name: "Multiplication by 2 Digit Numbers",
    Category: "Numbers, Place Value, Four Operations",
  },
  {
    Id: "7",
    Name: "Parallel Lines",
    Category: "Parallel Lines, Perpendicular Lines, 2D, 3D Shapes",
  },
  {
    Id: "10",
    Name: "Perpendicular Lines",
    Category: "Parallel Lines, Perpendicular Lines, 2D, 3D Shapes",
  },
  {
    Id: "24",
    Name: "Reading Time",
    Category: "Time, Speed",
  },
  {
    Id: "25",
    Name: "Time - Duration",
    Category: "Time, Speed",
  },
  {
    Id: "17",
    Name: "Speed",
    Category: "Time, Speed",
  },
  {
    Id: "18",
    Name: "Speed - Catch Up",
    Category: "Time, Speed",
  },
];

const BrainGameModule = [
  {
    category: "Memory",
    data: [
      {
        Id: 4,
        GameFolder: "mgmemorygame2",
        GameUrl: null,
        Name: "Animal Memory",
        ThumbnailUrl:
          "https://koobits.blob.core.windows.net/spp/arena/gameui/images/mgmemorygame2.png",
        Unlocked: false,
        PriceToUnlock: 1,
      },
      {
        Id: 48,
        GameFolder: "mgevenodd",
        GameUrl: null,
        Name: "Even Odd",
        ThumbnailUrl:
          "https://koobits.blob.core.windows.net/spp/arena/gameui/images/mgevenodd.png",
        Unlocked: false,
        PriceToUnlock: 1,
      },
      {
        Id: 5,
        GameFolder: "mgflashmemory",
        GameUrl: null,
        Name: "Flash Memory",
        ThumbnailUrl:
          "https://koobits.blob.core.windows.net/spp/arena/gameui/images/mgflashmemory.png",
        Unlocked: false,
        PriceToUnlock: 1,
      },
      {
        Id: 2,
        GameFolder: "mgmemoryblock",
        GameUrl: null,
        Name: "Memory Block",
        ThumbnailUrl:
          "https://koobits.blob.core.windows.net/spp/arena/gameui/images/mgmemoryblock.png",
        Unlocked: false,
        PriceToUnlock: 1,
      },
      {
        Id: 3,
        GameFolder: "mgmemorygame",
        GameUrl: null,
        Name: "Memory Game",
        ThumbnailUrl:
          "https://koobits.blob.core.windows.net/spp/arena/gameui/images/mgmemorygame.png",
        Unlocked: false,
        PriceToUnlock: 1,
      },
      {
        Id: 49,
        GameFolder: "mgmemoryorder",
        GameUrl: null,
        Name: "Memory Order",
        ThumbnailUrl:
          "https://koobits.blob.core.windows.net/spp/arena/gameui/images/mgmemoryorder.png",
        Unlocked: false,
        PriceToUnlock: 1,
      },
      {
        Id: 50,
        GameFolder: "mgsequence",
        GameUrl: null,
        Name: "Sequence",
        ThumbnailUrl:
          "https://koobits.blob.core.windows.net/spp/arena/gameui/images/mgsequence.png",
        Unlocked: false,
        PriceToUnlock: 1,
      },
      {
        Id: 6,
        GameFolder: "mgwhatisinside",
        GameUrl: null,
        Name: "What is Inside",
        ThumbnailUrl:
          "https://koobits.blob.core.windows.net/spp/arena/gameui/images/mgwhatisinside.png",
        Unlocked: false,
        PriceToUnlock: 1,
      },
    ],
  },
  {
    category: "Flexibility",
    data: [
      {
        Id: 23,
        GameFolder: "flbrickout",
        GameUrl: null,
        Name: "Brick Out",
        ThumbnailUrl:
          "https://koobits.blob.core.windows.net/spp/arena/gameui/images/flbrickout.png",
        Unlocked: false,
        PriceToUnlock: 1,
      },
      {
        Id: 24,
        GameFolder: "flearthattack",
        GameUrl: null,
        Name: "Earth Attack",
        ThumbnailUrl:
          "https://koobits.blob.core.windows.net/spp/arena/gameui/images/flearthattack.png",
        Unlocked: true,
        PriceToUnlock: 1,
      },
      {
        Id: 25,
        GameFolder: "flfishingfrenzy",
        GameUrl: null,
        Name: "Fishing Frenzy",
        ThumbnailUrl:
          "https://koobits.blob.core.windows.net/spp/arena/gameui/images/flfishingfrenzy.png",
        Unlocked: false,
        PriceToUnlock: 1,
      },
      {
        Id: 46,
        GameFolder: "flgoldminer",
        GameUrl: null,
        Name: "Gold Miner",
        ThumbnailUrl:
          "https://koobits.blob.core.windows.net/spp/arena/gameui/images/flgoldminer.png",
        Unlocked: false,
        PriceToUnlock: 1,
      },
      {
        Id: 26,
        GameFolder: "flmadshark",
        GameUrl: null,
        Name: "Mad Shark",
        ThumbnailUrl:
          "https://koobits.blob.core.windows.net/spp/arena/gameui/images/flmadshark.png",
        Unlocked: false,
        PriceToUnlock: 1,
      },
      {
        Id: 47,
        GameFolder: "flmonstertruck",
        GameUrl: null,
        Name: "Monster Truck",
        ThumbnailUrl:
          "https://koobits.blob.core.windows.net/spp/arena/gameui/images/flmonstertruck.png",
        Unlocked: false,
        PriceToUnlock: 1,
      },
      {
        Id: 27,
        GameFolder: "flmathpluspuzzle",
        GameUrl: null,
        Name: "Number Castle",
        ThumbnailUrl:
          "https://koobits.blob.core.windows.net/spp/arena/gameui/images/flmathpluspuzzle.png",
        Unlocked: false,
        PriceToUnlock: 1,
      },
      {
        Id: 28,
        GameFolder: "flsnakeblocks",
        GameUrl: null,
        Name: "Snake and Blocks",
        ThumbnailUrl:
          "https://koobits.blob.core.windows.net/spp/arena/gameui/images/flsnakeblocks.png",
        Unlocked: false,
        PriceToUnlock: 1,
      },
    ],
  },
  {
    category: "Speed",
    data: [
      {
        Id: 38,
        GameFolder: "spbrainspeed",
        GameUrl: null,
        Name: "Brain Speed",
        ThumbnailUrl:
          "https://koobits.blob.core.windows.net/spp/arena/gameui/images/spbrainspeed.png",
        Unlocked: false,
        PriceToUnlock: 1,
      },
      {
        Id: 39,
        GameFolder: "spdefensemaths",
        GameUrl: null,
        Name: "Defense Maths",
        ThumbnailUrl:
          "https://koobits.blob.core.windows.net/spp/arena/gameui/images/spdefensemaths.png",
        Unlocked: false,
        PriceToUnlock: 1,
      },
      {
        Id: 34,
        GameFolder: "spmathballsgame",
        GameUrl: null,
        Name: "Maths Balls Game",
        ThumbnailUrl:
          "https://koobits.blob.core.windows.net/spp/arena/gameui/images/spmathballsgame.png",
        Unlocked: false,
        PriceToUnlock: 1,
      },
      {
        Id: 40,
        GameFolder: "spmathspop",
        GameUrl: null,
        Name: "Maths Pop Game",
        ThumbnailUrl:
          "https://koobits.blob.core.windows.net/spp/arena/gameui/images/spmathspop.png",
        Unlocked: false,
        PriceToUnlock: 1,
      },
      {
        Id: 41,
        GameFolder: "spmathsspeed",
        GameUrl: null,
        Name: "Maths Speed",
        ThumbnailUrl:
          "https://koobits.blob.core.windows.net/spp/arena/gameui/images/spmathsspeed.png",
        Unlocked: false,
        PriceToUnlock: 1,
      },
      {
        Id: 35,
        GameFolder: "spsolvemaths",
        GameUrl: null,
        Name: "Solve Maths",
        ThumbnailUrl:
          "https://koobits.blob.core.windows.net/spp/arena/gameui/images/spsolvemaths.png",
        Unlocked: false,
        PriceToUnlock: 1,
      },
      {
        Id: 36,
        GameFolder: "spsushimaths",
        GameUrl: null,
        Name: "Sushi Maths",
        ThumbnailUrl:
          "https://koobits.blob.core.windows.net/spp/arena/gameui/images/spsushimaths.png",
        Unlocked: false,
        PriceToUnlock: 1,
      },
      {
        Id: 37,
        GameFolder: "sptruenumber",
        GameUrl: null,
        Name: "True Number",
        ThumbnailUrl:
          "https://koobits.blob.core.windows.net/spp/arena/gameui/images/sptruenumber.png",
        Unlocked: false,
        PriceToUnlock: 1,
      },
    ],
  },
  {
    category: "Attention",
    data: [
      {
        Id: 16,
        GameFolder: "atcubeninja",
        GameUrl: null,
        Name: "Cube Ninja",
        ThumbnailUrl:
          "https://koobits.blob.core.windows.net/spp/arena/gameui/images/atcubeninja.png",
        Unlocked: false,
        PriceToUnlock: 1,
      },
      {
        Id: 17,
        GameFolder: "atduckshoot",
        GameUrl: null,
        Name: "Duck Shoot",
        ThumbnailUrl:
          "https://koobits.blob.core.windows.net/spp/arena/gameui/images/atduckshoot.png",
        Unlocked: false,
        PriceToUnlock: 1,
      },
      {
        Id: 45,
        GameFolder: "atflipcat",
        GameUrl: null,
        Name: "Flip Cat",
        ThumbnailUrl:
          "https://koobits.blob.core.windows.net/spp/arena/gameui/images/atflipcat.png",
        Unlocked: false,
        PriceToUnlock: 1,
      },
      {
        Id: 18,
        GameFolder: "atfruitslasher",
        GameUrl: null,
        Name: "Fruit Slasher",
        ThumbnailUrl:
          "https://koobits.blob.core.windows.net/spp/arena/gameui/images/atfruitslasher.png",
        Unlocked: false,
        PriceToUnlock: 1,
      },
      {
        Id: 20,
        GameFolder: "atspaceracer",
        GameUrl: null,
        Name: "Space Racer",
        ThumbnailUrl:
          "https://koobits.blob.core.windows.net/spp/arena/gameui/images/atspaceracer.png",
        Unlocked: false,
        PriceToUnlock: 1,
      },
      {
        Id: 19,
        GameFolder: "atspaceshoot",
        GameUrl: null,
        Name: "Space Shoot",
        ThumbnailUrl:
          "https://koobits.blob.core.windows.net/spp/arena/gameui/images/atspaceshoot.png",
        Unlocked: false,
        PriceToUnlock: 1,
      },
      {
        Id: 21,
        GameFolder: "attankdefender",
        GameUrl: null,
        Name: "Tank Defender",
        ThumbnailUrl:
          "https://koobits.blob.core.windows.net/spp/arena/gameui/images/attankdefender.png",
        Unlocked: false,
        PriceToUnlock: 1,
      },
      {
        Id: 22,
        GameFolder: "attrexrunner",
        GameUrl: null,
        Name: "T-Rex Runner",
        ThumbnailUrl:
          "https://koobits.blob.core.windows.net/spp/arena/gameui/images/attrexrunner.png",
        Unlocked: false,
        PriceToUnlock: 1,
      },
    ],
  },
  {
    category: "Problem Solving",
    data: [
      {
        Id: 7,
        GameFolder: "psbattleshipwar",
        GameUrl: null,
        Name: "Battleship War",
        ThumbnailUrl:
          "https://koobits.blob.core.windows.net/spp/arena/gameui/images/psbattleshipwar.png",
        Unlocked: false,
        PriceToUnlock: 1,
      },
      {
        Id: 8,
        GameFolder: "psfishworldmatch",
        GameUrl: null,
        Name: "Fish World Match",
        ThumbnailUrl:
          "https://koobits.blob.core.windows.net/spp/arena/gameui/images/psfishworldmatch.png",
        Unlocked: false,
        PriceToUnlock: 1,
      },
      {
        Id: 9,
        GameFolder: "psjellymatch",
        GameUrl: null,
        Name: "Jelly Match",
        ThumbnailUrl:
          "https://koobits.blob.core.windows.net/spp/arena/gameui/images/psjellymatch.png",
        Unlocked: false,
        PriceToUnlock: 1,
      },
      {
        Id: 10,
        GameFolder: "psplayfulkitty",
        GameUrl: null,
        Name: "Playful Kitty",
        ThumbnailUrl:
          "https://koobits.blob.core.windows.net/spp/arena/gameui/images/psplayfulkitty.png",
        Unlocked: false,
        PriceToUnlock: 1,
      },
      {
        Id: 51,
        GameFolder: "psplumber",
        GameUrl: null,
        Name: "Plumber",
        ThumbnailUrl:
          "https://koobits.blob.core.windows.net/spp/arena/gameui/images/psplumber.png",
        Unlocked: false,
        PriceToUnlock: 1,
      },
      {
        Id: 11,
        GameFolder: "psswiftcats",
        GameUrl: null,
        Name: "Swift Cats",
        ThumbnailUrl:
          "https://koobits.blob.core.windows.net/spp/arena/gameui/images/psswiftcats.png",
        Unlocked: false,
        PriceToUnlock: 1,
      },
      {
        Id: 12,
        GameFolder: "pstetriscube",
        GameUrl: null,
        Name: "Tetris Cube",
        ThumbnailUrl:
          "https://koobits.blob.core.windows.net/spp/arena/gameui/images/pstetriscube.png",
        Unlocked: false,
        PriceToUnlock: 1,
      },
      {
        Id: 13,
        GameFolder: "psultimatesudoku",
        GameUrl: null,
        Name: "Ultimate Sudoku",
        ThumbnailUrl:
          "https://koobits.blob.core.windows.net/spp/arena/gameui/images/psultimatesudoku.png",
        Unlocked: false,
        PriceToUnlock: 1,
      },
      {
        Id: 14,
        GameFolder: "psvirusattack",
        GameUrl: null,
        Name: "Virus Attack",
        ThumbnailUrl:
          "https://koobits.blob.core.windows.net/spp/arena/gameui/images/psvirusattack.png",
        Unlocked: false,
        PriceToUnlock: 1,
      },
      {
        Id: 15,
        GameFolder: "pszombieshooter",
        GameUrl: null,
        Name: "Zombie Shooter",
        ThumbnailUrl:
          "https://koobits.blob.core.windows.net/spp/arena/gameui/images/pszombieshooter.png",
        Unlocked: false,
        PriceToUnlock: 1,
      },
    ],
  },
  {
    category: "Adventure",
    data: [
      {
        Id: 29,
        GameFolder: "adbattlebattle",
        GameUrl: null,
        Name: "Battle Battle",
        ThumbnailUrl:
          "https://koobits.blob.core.windows.net/spp/arena/gameui/images/adbattlebattle.png",
        Unlocked: false,
        PriceToUnlock: 1,
      },
      {
        Id: 44,
        GameFolder: "adflashadventure",
        GameUrl: null,
        Name: "Flash Adventure",
        ThumbnailUrl:
          "https://koobits.blob.core.windows.net/spp/arena/gameui/images/adflashadventure.png",
        Unlocked: false,
        PriceToUnlock: 1,
      },
      {
        Id: 30,
        GameFolder: "adindiara",
        GameUrl: null,
        Name: "Indiara and Skull Gold",
        ThumbnailUrl:
          "https://koobits.blob.core.windows.net/spp/arena/gameui/images/adindiara.png",
        Unlocked: false,
        PriceToUnlock: 1,
      },
      {
        Id: 31,
        GameFolder: "adpirates",
        GameUrl: null,
        Name: "Pirates Adventure",
        ThumbnailUrl:
          "https://koobits.blob.core.windows.net/spp/arena/gameui/images/adpirates.png",
        Unlocked: false,
        PriceToUnlock: 1,
      },
      {
        Id: 32,
        GameFolder: "adprincessgoldblade",
        GameUrl: null,
        Name: "Princess Goldblade",
        ThumbnailUrl:
          "https://koobits.blob.core.windows.net/spp/arena/gameui/images/adprincessgoldblade.png",
        Unlocked: false,
        PriceToUnlock: 1,
      },
      {
        Id: 33,
        GameFolder: "adsnowballworld",
        GameUrl: null,
        Name: "Snowball World",
        ThumbnailUrl:
          "https://koobits.blob.core.windows.net/spp/arena/gameui/images/adsnowballworld.png",
        Unlocked: false,
        PriceToUnlock: 1,
      },
      {
        Id: 43,
        GameFolder: "adstickmonkey",
        GameUrl: null,
        Name: "Stick Monkey",
        ThumbnailUrl:
          "https://koobits.blob.core.windows.net/spp/arena/gameui/images/adstickmonkey.png",
        Unlocked: false,
        PriceToUnlock: 1,
      },
      {
        Id: 42,
        GameFolder: "adzombiebuster",
        GameUrl: null,
        Name: "Zombie Buster",
        ThumbnailUrl:
          "https://koobits.blob.core.windows.net/spp/arena/gameui/images/adzombiebuster.png",
        Unlocked: false,
        PriceToUnlock: 1,
      },
    ],
  },
];

const reduceDataArray = (data) => {
  const initialValue = [];
  return data.reduce((accum, el) => {
    return accum.concat(el.data);
  }, initialValue);
};

export const BrainGameData = reduceDataArray(BrainGameModule);
