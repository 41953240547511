import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import loginReducer from "store/login/loginSlice";
import courseReducer from "store/course/courseSlice";
import topicReducer from "store/topic/topicSlice";
import lessonReducer from "store/lesson/lessonSlice";
import activityReducer from "store/activity/activitySlice";
import { createLogger } from "redux-logger";

const middleware = [
  /* YOUR CUSTOM MIDDLEWARES HERE */
  ...getDefaultMiddleware(),
];

if (process.env.NODE_ENV !== "production") {
  const logger = createLogger();
  // add logger middleware
  middleware.push(logger);
}

const combinedReducer = combineReducers({
  login: loginReducer,
  course: courseReducer,
  topic: topicReducer,
  lesson: lessonReducer,
  activity: activityReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "reset-store") {
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }
  return combinedReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware,
});

export default store;
