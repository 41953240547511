import React from "react";
import { Form, Input, InputNumber, Button, Row, Col, Select } from "antd";

const { TextArea } = Input;

const LessonForm = ({
  onFinish,
  onFinishFailed,
  lessonData,
  editable,
  topics,
}) => {
  const {
    name,
    displayOrder,
    thumbnail,
    topicId,
    description,
    minAge,
    maxAge,
    maxpoints,
    minPointsToUnlock,
    // minGrade,
    // lessonStatus,
    // currentUserPoint,
    // status,
  } = lessonData;

  return (
    <Form
      name="basic"
      initialValues={{
        name,
        displayOrder,
        thumbnail,
        topicId,
        description,
        minAge,
        maxAge,
        maxpoints,
        minPointsToUnlock,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row>
        <Col xs={{ span: 24 }}>
          <Form.Item
            label="Name"
            name="name"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: "Please input name!",
              },
            ]}
          >
            <Input disabled={editable} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        label="Display Order"
        name="displayOrder"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        rules={[
          {
            required: true,
            message: "Please input display order!",
          },
        ]}
      >
        <InputNumber disabled={editable} />
      </Form.Item>
      <Row>
        <Col sm={{ span: 8 }}>
          <Form.Item
            label="Max Points"
            name="maxpoints"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            style={{ paddingRight: 10 }}
            rules={[
              {
                required: true,
                type: "number",
                min: 0,
                message: "Max Points must be more than/equal to 0",
              },
            ]}
          >
            <InputNumber disabled={editable} />
          </Form.Item>
        </Col>
        <Col sm={{ span: 8 }}>
          <Form.Item
            label="Min Points To Unlock"
            name="minPointsToUnlock"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <InputNumber disabled />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col sm={{ span: 8 }}>
          <Form.Item
            label="Minumum Age"
            name="minAge"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            style={{ paddingRight: 10 }}
          >
            <InputNumber disabled={editable} />
          </Form.Item>
        </Col>
        <Col sm={{ span: 8 }}>
          <Form.Item
            label="Maximum Age"
            name="maxAge"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <InputNumber disabled={editable} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 24 }}>
          <Form.Item
            label="Topic Name"
            name="topicId"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Select disabled={editable}>
              {topics.map((topic) => (
                <Select.Option key={topic.id} value={topic.id}>
                  {topic.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 24 }}>
          <Form.Item
            label="Description"
            name="description"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <TextArea disabled={editable} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 24 }}>
          <Form.Item
            label="Thumbnail"
            name="thumbnail"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Input disabled={editable} />
          </Form.Item>
        </Col>
      </Row>
      {!editable && (
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      )}
    </Form>
  );
};

export default LessonForm;
